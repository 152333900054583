<main-header></main-header>
<main>
  <!-- hero section -->
  <div class="!bg-cover !bg-no-repeat uptal-cover-section relative">
    <video
      class="object-cover bg-gray-900"
      playsinline
      autoplay
      [muted]="true"
      preload="auto"
      id="myVideo"
    >
      <source
        src="/assets/images/home/hero-video-5.mp4"
        type="video/mp4"
      />
    </video>
    <div class="h-screen pt-44 md:pt-36 flex flex-col">
      <div
        class="flex-1 flex items-end pb-16 md:pb-0 md:items-center page-max-width page-default-padding w-full md:justify-start justify-center"
      >
        <div class="flex flex-col items-start space-y-2 md:space-y-2">
          <h1
            class="!leading-tight font-bold text-3xl md:text-4xl text-white"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            Upscale Your Teams
            <br />
            <span class="whitespace-nowrap">
              With
              <span class="text-blue-400">AI Recruitment</span>
            </span>
          </h1>
          <div
            class="text-white text-xl font-thin max-w-[420px] text-left"
            data-aos-delay="300"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p>
              Advanced resume analysis, insightful one-way and live video
              interviews, and unmatched headhunting.
            </p>

            <div class="my-4 flex gap-x-8 text-lg">
              <div>
                <span class="font-bold text-blue-400">3X</span>
                <span class="text-blue-400 font-normal"> Faster</span>
              </div>
              <div>
                <span class="font-bold text-blue-400">79%</span>
                <span class="text-blue-400 font-normal"> Savings</span>
              </div>
            </div>
          </div>
          <div
            class="w-full md:w-auto"
            data-aos-delay="600"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div class="flex flex-col md:flex-row gap-y-2 gap-x-4">
              <input
                class="input w-42 md:w-80"
                placeholder="Enter your company email"
                (keyup)="validateEmailKeyUp($event)"
                [(ngModel)]="companyEmail"
              />
              <button
                *ngIf="!savingEmail"
                (click)="openVideo()"
                class="button cursor-pointer primary transition-colors whitespace-nowrap gap-x-2 !hidden md:!flex"
              >
                <p class="group-hover:underline">See how it works</p>
                <mat-icon class="!text-lg !w-auto !h-auto"
                  >play_circle</mat-icon
                >
              </button>
              <div
                *ngIf="savingEmail"
                class="w-44 lg:flex items-center justify-center mx-auto button cursor-not-allowed primary transition-colors whitespace-nowrap gap-x-2 !hidden md:!flex"
              >
                <mat-spinner diameter="20"></mat-spinner>
              </div>
              <div
                class="flex space-s-1 items-center text-white text-sm cursor-pointer group md:!hidden justify-center"
              >
                <ng-container *ngIf="!savingEmail">
                  <p class="group-hover:underline" (click)="openVideo()">
                    See how it works
                  </p>
                  <mat-icon class="!text-lg !w-auto !h-auto">
                    play_circle
                  </mat-icon>
                </ng-container>
                <ng-container *ngIf="savingEmail">
                  <mat-spinner diameter="20"></mat-spinner>
                </ng-container>
              </div>
            </div>
            <div
              *ngIf="emailError !== ''"
              class="px-2 py-1 text-sm text-red-500"
            >
              {{ emailError }}
            </div>

            <div
              class="pl-4 hidden md:flex space-s-1 items-center text-white text-sm pt-1.5 cursor-pointer group"
            >
              <a
                [href]="getStartedUrl()"
                target="_blank"
                class="group-hover:underline"
              >
                Get Started for Free
              </a>
              <mat-icon class="!text-lg !w-auto !h-auto"
                >arrow_forward</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
      <div class="z-10 md:hidden flex justify-center py-1.5 bg-gray-600">
        <div
          class="cursor-pointer flex space-s-1 items-center text-white text-sm group"
        >
          <a
            [href]="getStartedUrl()"
            target="_blank"
            class="group-hover:underline"
            >Get Started for Free</a
          >
          <mat-icon class="!text-lg !w-auto !h-auto">arrow_forward</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="page-max-width page-default-padding !max-w-screen-xl">
      <div data-aos="fade-up" class="space-y-10 py-12 md:py-20">
        <div>
          <p
            class="text-center text-2xl md:text-3xl font-bold text-gray-700 pb-4"
          >
            Choice of Industry Leaders
          </p>
          <p class="text-center text-xl font-thin text-gray-500">
            Uptal is the top choice for companies embracing modern recruitment
            technology.
          </p>
        </div>
        <div
          class="flex justify-center items-center flex-wrap gap-x-14 gap-y-10"
        >
          <img
            *ngFor="let trustedCompany of trustedCompanies"
            [style.height.px]="trustedCompany.height"
            [style.width]="'auto'"
            [src]="trustedCompany.src"
            alt="trusted companies logo"
            [height]="trustedCompany.h"
            [width]="trustedCompany.w"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="features" class="uptal-recruitment-modules uptal-secondary-section">
    <div
      data-aos="fade"
      class="page-max-width page-default-padding py-12 md:py-20 space-y-4"
    >
      <div class="space-y-4 !max-w-screen-lg m-auto">
        <div>
          <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
            Where Recruitment is No Longer an Obstacle
          </p>
        </div>
        <ng-container
          *ngFor="let module of recruitmentModules; let index = index"
        >
          <div
            class="flex justify-center"
            [class.hidden]="index !== selectedRecruitmentModules"
            data-aos="fade"
            data-aos-duration="500"
            #recruitmentModuleContainer
          >
            <video
              *ngIf="module.media_type === 'video'"
              [muted]="true"
              [autoplay]="index === selectedRecruitmentModules"
              [loop]="true"
              [controls]="false"
              width="1128"
              height="642"
              controls
            >
              <source [src]="module.video" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img
              *ngIf="module.media_type === 'image'"
              [src]="module.image"
              alt="ATS"
              width="2204"
              height="1392"
              loading="lazy"
            />
          </div>
        </ng-container>
      </div>
      <div class="space-y-4 !max-w-screen-lg m-auto">
        <div
          class="px-6 -mx-6 md:mx-0 md:px-0 overflow-x-scroll py-1 snap scrollbar-hide cursor-grab flex md:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-2 gap-x-3"
        >
          <button
            *ngFor="let module of recruitmentModules; let index = index"
            class="uptal-recruitment-modules-button basis-5/12 flex-shrink-0"
            [class.active]="index === selectedRecruitmentModules"
            (click)="onRecruitmentModuleClick(index)"
          >
            {{ module.button }}
          </button>
        </div>
        <ng-container
          *ngFor="let module of recruitmentModules; let index = index"
        >
          <div
            class="space-y-3 pt-4"
            *ngIf="index === selectedRecruitmentModules"
            data-aos="fade"
            data-aos-duration="500"
          >
            <p class="font-bold text-gray-700 text-xl md:text-3xl">
              {{ module.button }}
            </p>
            <p class="text-gray-500">
              {{ module.description }}
            </p>
            <a
              [href]="getStartedUrl()"
              target="_blank"
              class="flex items-center cursor-pointer group text-primary text-sm"
            >
              <p class="group-hover:underline font-medium">Get Started for Free</p>
              <mat-icon class="scale-90">chevron_right</mat-icon>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- <div id="sample-technical-interview">
    <div class="page-max-width page-default-padding py-12 md:py-20">
      <div data-aos="fade" class="space-y-8 !max-w-screen-lg m-auto">
        <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
          Sample Technical
          <br class="block md:hidden" />
          Interview
        </p>
        <div class="max-w-screen-sm m-auto">
          <p class="text-center text-xl font-thin text-gray-500">
            A quick glance at the employer's perspective, coupled with the
            powerful AI analysis and evaluation of a highly technical interview
          </p>
        </div>
        <div class="flex justify-center">
          <a
            [href]="getStartedUrl()"
            target="_blank"
            class="button primary transition-colors whitespace-nowrap"
          >
            Get Started for Free
          </a>
        </div>
        <video
          width="100%"
          [muted]="true"
          [autoplay]="true"
          poster="assets/images/home/sample_tech.webp"
        >
          <source
            src="https://www.w3schools.com/tags/movie.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div> -->
  <div id="projected-savings" class="">
    <div class="page-max-width page-default-padding py-12 md:py-20 space-y-16">
      <div data-aos="fade" class="space-y-6 !max-w-screen-lg m-auto">
        <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
          Projected Savings for {{ interviewsCount }} Hires
        </p>
        <p class="text-center text-[30px] md:text-5xl font-bold text-primary">
          SR {{ price }}
        </p>
        <div class="space-y-2">
          <mat-card class="!rounded-xl">
            <mat-card-content class="!p-1 !px-8">
              <div class="flex items-center space-s-5">
                <p class="font-medium">100</p>
                <mat-slider
                  min="100"
                  max="2000"
                  step="1"
                  style="width: calc(100% - 16px) !important"
                  discrete
                >
                  <input matSliderThumb [(ngModel)]="interviewsCount" aria-label="hires count" />
                </mat-slider>
                <p class="font-medium">2000</p>
              </div>
            </mat-card-content>
          </mat-card>
          <p class="text-center text-gray-500">
            Adjust the slider to set your desired number of hires
          </p>
        </div>
      </div>
      <div class="space-y-3 !max-w-screen-lg m-auto">
        <p
          data-aos="fade-right"
          class="font-bold text-gray-700 text-center md:text-left text-2xl md:text-3xl"
        >
          Tailored Savings Estimates
        </p>
        <div
          data-aos="fade-right"
          class="flex space-y-3 md:space-y-0 md:space-s-8 md:flex-row items-center justify-between flex-col"
        >
          <p
            class="text-gray-600 text-xl font-thin md:max-w-screen-sm text-center md:text-left"
          >
            Every organization is unique, and so are its savings potentials.
            Connect with one of our experts to get a precise estimate tailored
            to your specific hiring situation.
          </p>
          <a
            data-aos="fade-left"
            [href]="getStartedUrl()"
            target="_blank"
            class="button primary transition-colors whitespace-nowrap"
          >
            Talk to Expert
          </a>
        </div>
      </div>
    </div>
  </div>

  <div id="english-arabic" class="uptal-secondary-section">
    <div class="page-max-width page-default-padding py-12 md:py-20 space-y-16">
      <div data-aos="fade" class="space-y-6 !max-w-screen-lg m-auto">
        <div class="w-full flex justify-center">
          <!-- <img
            src="assets/images/home/english-arabic.svg"
            alt="english-arabic-icon"
            class="h-20 w-auto md:hidden"
          /> -->
        </div>
        <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
          Optimized for English and Arabic Interviews
        </p>
        <div class="max-w-screen-lg m-auto">
          <div class="flex gap-x-8 items-center">
            <!-- <div>
              <img src="assets/images/home/english-arabic.svg" alt="english-arabic-icon" class="h-32 w-auto hidden md:block">
            </div> -->
            <div>
              <p class="text-start text-xl font-thin text-gray-500">
                Uptal supports interviews in both English and Arabic, offering a
                seamless and efficient evaluation process for these languages.
                This feature ensures your hiring process is inclusive and
                effective, catering to a diverse talent pool.
              </p>
            </div>
          </div>
          <div class="w-full flex justify-center pt-8">
            <img
              src="assets/images/home/english-arabic.svg"
              alt="english-arabic-icon"
              class="h-20 w-auto"
              width="76"
              height="75"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="acclerated-hiring" class="">
    <app-accelerated-hiring-section></app-accelerated-hiring-section>
  </div>

  <div id="additional-features" class="uptal-secondary-section">
    <div class="page-max-width page-default-padding py-12 md:py-20 space-y-16">
      <div data-aos="fade" class="space-y-5 !max-w-screen-lg m-auto">
        <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
          Additional Features
        </p>
        <div class="max-w-screen-lg m-auto">
          <p class="text-center text-xl font-thin text-gray-500">
            Enhance your recruitment process with our robust technical and
            psychometric assessments to accurately gauge candidate capabilities,
            alongside efficient workflow & automations that streamline task
            management.
          </p>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-5">
          <mat-card data-aos="fade-up" class="!rounded-2xl overflow-hidden">
            <mat-card-content class="!p-4 h-full">
              <div class="flex h-full flex-col justify-between">
                <div class="px-2 py-2">
                  <div class="flex items-center space-s-2">
                    <!-- <img
                      src="assets/images/home/technical.svg"
                      alt="Technical"
                    /> -->
                    <p class="font-medium md:text-xl text-gray-700">
                      Technical Assessments
                    </p>
                  </div>
                  <p class="text-gray-500 py-2">
                    Test a candidate's expertise and knowledge in specific
                    subject areas, ensuring they possess the necessary skills
                    for the targeted job role.
                  </p>
                  <div>
                  </div>
                </div>
                <div class="-mx-4 !-mb-4 border-t-2">
                  <img
                    src="assets/images/home/features/technical-image.webp"
                    alt="Technical"
                    width="600"
                    height="314"
                    loading="lazy"
                  />
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card
            data-aos="fade-up"
            data-aos-delay="300"
            class="!rounded-2xl overflow-hidden"
          >
            <mat-card-content class="!p-4 h-full">
              <div class="flex h-full flex-col justify-between">
                <div class="px-2 py-2">
                  <div class="flex items-center space-s-2">
                    <!-- <img
                      src="assets/images/home/psychometric.svg"
                      alt="Psychometric"
                    /> -->
                    <p class="font-medium md:text-xl text-gray-700">
                      Psychometric Assessments
                    </p>
                  </div>
                  <p class="text-gray-500 py-2">
                    Comprehensive evaluation of candidates, utilizing a range of
                    tools including aptitude tests, situational judgement tests,
                    IQ tests, and emotional intelligence tests. These
                    assessments, available in multiple choice and video formats,
                    are designed to gauge a candidate's skills, decision-making
                    abilities, intellectual potential, and emotional and
                    behavioral traits, aiding in identifying the most suitable
                    candidates for specific roles.
                  </p>
                  <div>
                  </div>
                </div>
                <div class="-mx-4 !-mb-4 border-t-2">
                  <img
                    src="assets/images/home/features/psychometric-image.webp"
                    alt="Psychometric"
                    width="600"
                    height="314"
                    loading="lazy"
                  />
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card
            data-aos="fade-up"
            data-aos-delay="600"
            class="!rounded-2xl overflow-hidden"
          >
            <mat-card-content class="!p-4 h-full">
              <div class="flex h-full flex-col justify-between">
                <div class="px-2 py-2">
                  <div class="flex items-center space-s-2">
                    <!-- <img src="assets/images/home/workflow.svg" alt="Workflow" /> -->
                    <p class="font-medium md:text-xl text-gray-700">
                      Workflow & Automations
                    </p>
                  </div>
                  <p class="text-gray-500 py-2">
                    Automates essential recruitment tasks such as closing
                    application forms based on received CV volume or scores,
                    sending invitations for one-way video interviews,
                    distributing job requisitions, and handling managerial
                    approvals. This ensures a more efficient and effective
                    management of the hiring process.
                  </p>
                  <div>
                  </div>
                </div>
                <div class="-mx-4 !-mb-4 border-t-2">
                  <img
                    src="assets/images/home/features/workflow-image.webp"
                    alt="Workflow"
                    width="600"
                    height="314"
                    loading="lazy"
                  />
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div data-aos="fade-up" class="flex justify-center">
        <a
          [href]="getStartedUrl()"
          target="_blank"
          class="button primary transition-colors whitespace-nowrap"
        >
          Get Started for Free
        </a>
      </div>
    </div>
  </div>

  <div id="integrations" class="">
    <div class="page-max-width page-default-padding py-12 md:py-20 space-y-14">
      <p
        data-aos="fade-up"
        class="text-center text-2xl md:text-3xl font-bold text-gray-700"
      >
        Uptal Connects With Your Tools
      </p>
      <p data-aos="fade-up" class="text-center text-xl font-thin text-gray-500">
        Uptal ensures that your hiring process is not only efficient but also
        seamlessly integrated with the tools you already utilize. Our platform
        is designed to effortlessly blend into your existing workflow, providing
        a unified, streamlined experience.
      </p>
      <div class="!max-w-screen-lg m-auto">
        <div class="flex justify-center flex-wrap">
          <img
            *ngFor="let i of [2, 3]"
            [src]="'assets/images/home/tools_' + i + '.svg'"
            [alt]="'tool ' + i"
            data-aos="fade-up"
            [attr.data-aos-delay]="(i - 1) * 100"
            class="md:w-auto w-20"
            height="123"
            width="123"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="uptal-secondary-section">
    <div class="page-max-width page-default-padding py-12 md:py-20 space-y-10">
      <p
        data-aos="fade-up"
        class="text-center text-2xl md:text-3xl font-bold text-gray-700"
      >
        Success Stories with Uptal’s
        <br />
        AI-Driven Hiring
      </p>
      <p data-aos="fade-up" class="text-center text-xl font-thin text-gray-500">
        Uptal is at the forefront, providing AI-driven hiring solutions that
        empower enterprises to navigate through the complexities of talent
        acquisition with ease and precision. Here’s how some have transformed
        their hiring journeys with Uptal.
      </p>
      <p
        data-aos="fade-right"
        class="text-md md:text-2xl font-bold text-gray-700"
      >
        What Companies are Saying
      </p>
      <div class="gap-x-4 columns-1 md:columns-3 !mt-0">
        <div
          class="inline-block mt-4 w-full"
          *ngFor="let comment of companiesComment; let index = index"
          data-aos="fade-up"
          [attr.data-aos-delay]="index * 100"
        >
          <mat-card class="!rounded-xl !border !border-solid !border-gray-300">
            <mat-card-header>
              <div class="flex space-s-2 justify-between w-full pb-4">
                <div class="flex space-s-2 items-center">
                  <img
                    class="h-12 w-12 rounded-full"
                    [src]="comment.avatar"
                    alt="avatar"
                  />
                  <div>
                    <mat-card-title class="!font-medium !text-md">
                      {{ comment.name }}
                    </mat-card-title>
                    <mat-card-subtitle>
                      {{ comment.title }}
                    </mat-card-subtitle>
                  </div>
                </div>
                <img src="assets/images/icons/quote.svg" alt="quote" width="40" height="35" />
              </div>
            </mat-card-header>
            <mat-card-content>
              <p class="text-gray-700">
                {{ comment.comment }}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div> -->

  <div *ngIf="false" class="page-max-width page-default-padding">
    <div class="m-auto">
      <hr class="border-t-2" />
    </div>
  </div>

  <div id="common-pitfalls" class="uptal-secondary-section">
    <div class="page-max-width page-default-padding py-12 md:py-20 space-y-10">
      <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
        Avoid the Common Recruitment Pitfalls
      </p>
      <div class="block md:hidden">
        <div class="uptal-pitfalls-group" data-aos="fade-up">
          <div class="uptal-pitfalls">
            <div data-aos="fade-up" data-aos-delay="800">
              <p class="uptal-pitfalls-header">Unconscious Bias</p>
              <p class="text-gray-500">
                Defend against the unseen,
                <span class="font-medium text-primary">
                  unintentional biases
                </span>
                that subtly infiltrate hiring decisions, ensuring every
                candidate is evaluated on merit alone
              </p>
            </div>
          </div>
          <div class="uptal-pitfalls">
            <div data-aos="fade-up" data-aos-delay="800">
              <p class="uptal-pitfalls-header">Inefficient Hiring Workflows</p>
              <p class="text-gray-500">
                Safeguard against the unnoticed drain of
                <span class="font-medium text-primary">
                  inefficient hiring practices,
                </span>
                ensuring your recruitment process is streamlined and
                cost-effective
              </p>
            </div>
          </div>
          <div class="uptal-pitfalls">
            <div data-aos="fade-up" data-aos-delay="800">
              <p class="uptal-pitfalls-header">Inconsistent Evaluation</p>
              <p class="text-gray-500">
                Avoid the risk of
                <span class="font-medium text-primary"> uneven reviews, </span>
                making sure all candidates are judged fairly and equally Guard
                against the subtle unfairness caused by
              </p>
            </div>
          </div>
          <div class="uptal-pitfalls">
            <div data-aos="fade-up" data-aos-delay="800">
              <p class="uptal-pitfalls-header">Applicant Unfairness</p>
              <p class="text-gray-500">
                Guard against the subtle unfairness caused by
                <span class="font-medium text-primary">
                  recruiter fatigue,
                </span>
                ensuring late applicants receive the same careful consideration
                as the first
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:block">
        <div class="flex justify-evenly space-s-4">
          <div class="w-1/4" data-aos="fade-up" data-aos-delay="800">
            <div>
              <p class="font-medium text-lg">Unconscious Bias</p>
              <p class="text-gray-500">
                Defend against the unseen,
                <span class="font-medium text-primary">
                  unintentional biases
                </span>
                that subtly infiltrate hiring decisions, ensuring every
                candidate is evaluated on merit alone
              </p>
            </div>
          </div>
          <div class="w-1/4" data-aos="fade-up" data-aos-delay="800">
            <div>
              <p class="font-medium text-lg">Inefficient Hiring Workflows</p>
              <p class="text-gray-500">
                Safeguard against the unnoticed drain of
                <span class="font-medium text-primary">
                  inefficient hiring practices,
                </span>
                ensuring your recruitment process is streamlined and
                cost-effective
              </p>
            </div>
          </div>
        </div>
        <div class="flex space-s-2">
          <div
            class="w-1/4 pb-8 flex items-end"
            data-aos="fade-right"
            data-aos-delay="800"
          >
            <div>
              <p class="font-medium text-lg">Inconsistent Evaluation</p>
              <p class="text-gray-500">
                Avoid the risk of
                <span class="font-medium text-primary"> uneven reviews, </span>
                making sure all candidates are judged fairly and equally Guard
                against the subtle unfairness caused by
              </p>
            </div>
          </div>
          <div class="relative w-2/4" data-aos="circle-svg-animation">
            <div>
              <svg
                class="w-full"
                version="1.1"
                width="582"
                height="291"
                viewBox="0 0 100 50"
                xmlns="http://www.w3.org/2000/svg"
              >
                <clipPath id="cut-off">
                  <rect x="0" y="0" width="100" height="50" />
                </clipPath>
                <circle
                  opacity=".2"
                  class="path"
                  stroke="#43ACFF"
                  stroke-width="3"
                  clip-path="url(#cut-off)"
                  cx="50"
                  cy="50"
                  r="45"
                  fill="transparent"
                />
                <circle cx="10.25" cy="29" r="2" fill="#43ACFF" />
                <circle cx="35" cy="7.5" r="2" fill="#43ACFF" />
                <circle cx="65" cy="7.5" r="2" fill="#43ACFF" />
                <circle cx="89.75" cy="29" r="2" fill="#43ACFF" />
              </svg>
            </div>
            <div
              class="w-full absolute bottom-0 flex justify-center"
              data-aos="fade"
              data-aos-delay="800"
            >
              <div class="w-1/2 flex flex-col items-center space-y-8">
                <p
                  class="font-bold text-lg md:text-3xl text-center text-gray-700"
                >
                  The Hidden Costs
                  <br />
                  of Traditional Hiring
                </p>
                <a
                  [href]="getStartedUrl()"
                  target="_blank"
                  class="button primary transition-colors whitespace-nowrap"
                >
                  Get Started for Free
                </a>
              </div>
            </div>
          </div>
          <div
            class="w-1/4 pb-8 flex items-end"
            data-aos="fade-left"
            data-aos-delay="800"
          >
            <div>
              <p class="font-medium text-lg">Applicant Unfairness</p>
              <p class="text-gray-500">
                Guard against the subtle unfairness caused by
                <span class="font-medium text-primary">
                  recruiter fatigue,
                </span>
                ensuring late applicants receive the same careful consideration
                as the first
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="candidate-satisfaction" class="page-max-width page-default-padding">
    <div class="m-auto">
      <hr class="border-t-2" />
    </div>
  </div>

  <div>
    <div class="page-max-width page-default-padding py-12 md:py-20 space-y-10">
      <p
        data-aos="fade-up"
        class="text-center text-2xl md:text-3xl font-bold text-gray-700"
      >
        94% of Candidates are Satisfied
      </p>
      <p data-aos="fade-up" class="text-center text-gray-500">
        A staggering 94% of candidates found our video interview process easy to
        navigate, experiencing no issues during their interactions. We’re
        dedicated to maintaining and improving this seamless experience for all
        future candidates.
      </p>
      <p
        data-aos="fade-right"
        class="text-md md:text-2xl font-bold text-gray-700"
      >
        What Candidates are Saying
      </p>
      <div class="gap-x-4 columns-1 md:columns-3 !mt-0">
        <div
          class="inline-block mt-4 w-full"
          *ngFor="let comment of candidatesComment; let index = index"
          data-aos="fade-up"
          [attr.data-aos-delay]="index * 100"
        >
          <mat-card class="!rounded-xl !border !border-solid !border-gray-300">
            <mat-card-header>
              <div class="flex space-s-2 justify-between w-full pb-4">
                <div class="flex space-s-2 items-center">
                  <!-- <img
                    class="h-12 w-12 rounded-full"
                    [src]="comment.avatar"
                    alt="avatar"
                  /> -->
                  <div>
                    <mat-card-title class="!font-medium !text-md">
                      {{ comment.name }}
                    </mat-card-title>
                    <mat-card-subtitle>
                      {{ comment.title }}
                    </mat-card-subtitle>
                  </div>
                </div>
                <img src="assets/images/icons/quote.svg" alt="quote" width="40" height="35" />
              </div>
            </mat-card-header>
            <mat-card-content>
              <p class="text-gray-700">
                {{ comment.comment }}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</main>
<main-footer></main-footer>
