import { Component } from '@angular/core';

@Component({
  selector: 'app-features-layout',
  templateUrl: './features-layout.component.html',
  styleUrls: ['./features-layout.component.scss']
})
export class FeaturesLayoutComponent {

}
