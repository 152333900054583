<footer class="bt-bg-dark py-12 relative overflow-hidden footer" aria-labelledby="footer-heading">
  <!-- <img
    class="absolute right-0 -bottom-6"
    src="assets/images/footer/uptal_logo.svg"
  /> -->
  <h2 id="footer-heading" class="sr-only">Footer</h2>
  <div class="page-max-width page-default-padding space-y-10">
    <div class="grid grid-cols-2 md:grid-cols-6 gap-8">
      <div data-aos="fade-right" class="md:col-span-2 space-y-5">
        <a routerLink="/">
          <img class="h-12 w-auto" src="assets/images/logo_white.svg" alt="Uptal logo" width="618" height="294" />
        </a>
        <p class="max-w-[300px] text-white">
          {{ 'footer.Enterprise_Solution' | translate }}
        </p>
        <div class="flex space-s-4 md:order-2">
          <!-- <a
            href="https://www.facebook.com/baseteam.io"
            target="_blank"
            class="text-gray-400 hover:text-gray-300"
          >
            <span class="sr-only">Facebook</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a> -->

          <!-- <a
            href="https://www.instagram.com/baseteam.io/"
            target="_blank"
            class="text-gray-400 hover:text-gray-300"
          >
            <span class="sr-only">Instagram</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd"
              />
            </svg>
          </a> -->

          <a href="https://www.linkedin.com/company/uptal-com/" target="_blank"
            class="text-white hover:text-primary-light">
            <span class="sr-only">LinkedIn</span>
            <svg class="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.0449 22.001C17.5678 22.001 22.0449 17.5238 22.0449 12.001C22.0449 6.47813 17.5678 2.00098 12.0449 2.00098C6.52208 2.00098 2.04492 6.47813 2.04492 12.001C2.04492 17.5238 6.52208 22.001 12.0449 22.001Z"
                fill="#262626" />
              <path
                d="M12 0C5.39623 0 0 5.40377 0 12C0 18.5962 5.40377 24 12 24C18.5962 24 24 18.5962 24 12C24 5.40377 18.6038 0 12 0ZM8.70189 18H6.20377V10H8.70189V18ZM7.40377 8.89811C6.60377 8.89811 6 8.19623 6 7.49434C6 6.69434 6.60377 5.99245 7.40377 5.99245C8.20377 5.99245 8.80755 6.59623 8.80755 7.39623C8.89811 8.19623 8.20377 8.89811 7.40377 8.89811ZM18 18H15.5019V14.0981C15.5019 13.2 15.5019 12 14.2038 12C12.9057 12 12.7019 13.0038 12.7019 14.0981V18.0981H10.2038V10.0981H12.6038V11.2C12.9057 10.5962 13.7057 9.90189 15.0038 9.90189C17.5019 9.90189 18.0075 11.6 18.0075 13.7057V18H18Z"
                fill="white" />
            </svg>
          </a>

          <a href="https://twitter.com/Uptal_Official" target="_blank" class="text-white hover:text-primary-light">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                fill="white" />
              <path
                d="M5.03615 5L10.772 12.9027L5 19.3279H6.29915L11.3526 13.7024L15.4356 19.3279H19.8563L13.7976 10.9808L19.1702 5H17.8711L13.2172 10.1808L9.4569 5H5.03615ZM6.94661 5.98597H8.97748L17.9456 18.342H15.9148L6.94661 5.98597Z"
                fill="#262626" />
            </svg>
          </a>
        </div>
      </div>
      <div data-aos="fade-left">
        <h3 class="font-medium text-white text-xl">
          {{ 'footer.Solutions' | translate }}
        </h3>
        <ul data-aos="fade-up" data-aos-delay="300" role="list" class="mt-4 space-y-4">
          <li>
            <a routerLink="/" fragment="features" class="text-gray-300 hover:text-white">
              {{ 'footer.Resume_Evaluation' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/" fragment="features" class="text-gray-300 hover:text-white">
              {{ 'footer.Interviews' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/" fragment="additional-features" class="text-gray-300 hover:text-white">
              {{ 'footer.Technical_Assessments' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/" fragment="additional-features" class="text-gray-300 hover:text-white">
              {{ 'footer.Psychometric_Assessments' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/" fragment="features" class="text-gray-300 hover:text-white">
              {{ 'footer.Headhunting' | translate }}
            </a>
          </li>
          <!-- <li>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdbLV1_b2vLu17gBJSzd4IruzIC1tmB9mzp4ma5jl3XOBLQWg/viewform?usp=sf_link"
              target="_blank" class="text-gray-300 hover:text-white">
              FAQ’s
            </a>
          </li> -->
          <li>
            <a href="https://app.uptal.com/interview/apply/MKzGqLorRZlxq4EbdVe24N8AQ?default_lang=en" target="_blank" class="text-gray-300 hover:text-white">
              {{ 'navbar.Looking_for_work' | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div data-aos="fade-left">
        <h3 class="font-medium text-white text-xl">
          {{ 'footer.Quick_Links' | translate }}
        </h3>
        <ul data-aos="fade-up" data-aos-delay="300" role="list" class="mt-4 space-y-4">
          <li>
            <a routerLink="/" class="text-gray-300 hover:text-white">
              {{ 'footer.Home' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/" fragment="integrations" class="text-gray-300 hover:text-white">
              {{ 'footer.Integrations' | translate }}
            </a>
          </li>
          <!-- <li>
            <a routerLink="/about-us" class="text-gray-300 hover:text-white">
              Reports
            </a>
          </li> -->
          <li>
            <a routerLink="/" fragment="additional-features" class="text-gray-300 hover:text-white">
              {{ 'footer.Automation' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/training-and-support" class="text-gray-300 hover:text-white">
              {{ 'footer.Training_Support' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/customization" class="text-gray-300 hover:text-white">
              {{ 'footer.Customization' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/about-us" class="text-gray-300 hover:text-white">
              {{ 'footer.About_Us' | translate }}
            </a>
          </li>
          <!-- <li>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdbLV1_b2vLu17gBJSzd4IruzIC1tmB9mzp4ma5jl3XOBLQWg/viewform?usp=sf_link"
              target="_blank" class="text-gray-300 hover:text-white">
              FAQ’s
            </a>
          </li> -->
        </ul>
      </div>
      <div data-aos="fade-left" data-aos-delay="300">
        <h3 class="font-medium text-white text-xl">
          {{ 'footer.Support' | translate }}
        </h3>
        <ul data-aos="fade-up" data-aos-delay="600" role="list" class="mt-4 space-y-4">
          <!-- <li>
            <a routerLink="/help" class="text-gray-300 hover:text-white"
              >Help</a
            >
          </li> -->
          <li>
            <a routerLink="/contact-us" class="text-gray-300 hover:text-white">
              {{ 'footer.Contact_Us' | translate }}

            </a>
          </li>
          <li>
            <a routerLink="/terms-and-conditions" class="text-gray-300 hover:text-white">
              {{ 'footer.Terms_Conditions' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/privacy-policy" class="text-gray-300 hover:text-white">
              {{ 'footer.Privacy_Policy' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/security-and-privacy" class="text-gray-300 hover:text-white">
              {{ 'footer.Data_Security' | translate }}
              </a>
          </li>
        </ul>
      </div>
      <div data-aos="fade-left" data-aos-delay="600">
        <h3 class="font-medium text-white text-xl">
          {{ 'footer.News_Events' | translate }}
          </h3>
        <ul data-aos="fade-up" data-aos-delay="900" role="list" class="mt-4 space-y-4">
          <li>
            <a routerLink="/press" class="text-gray-300 hover:text-white">
              {{ 'footer.Press' | translate }}
            </a>
          </li>
          <!-- <li>
            <a class="text-gray-300 hover:text-white">Blog</a>
          </li> -->
          <!-- <li>
            <a class="text-gray-300 hover:text-white">Events</a>
          </li> -->
        </ul>
      </div>
    </div>
    <div data-aos="fade" data-aos-delay="300" data-aos-anchor=".footer"
      class="flex md:flex-row flex-col gap-y-4 items-center justify-between">
      <p class="text-gray-300">
        {{ 'footer.Copyright' | translate }}
      </p>
      <div>
        <form class="mt-4 sm:max-w-xs">
          <select-language
            className="block w-full appearance-none rounded-md border border-transparent bg-gray-700 bg-none py-2 ps-3 pe-10 text-base text-white focus:border-white focus:outline-none focus:ring-white sm:text-sm">
          </select-language>
        </form>
      </div>
    </div>
  </div>
</footer>
