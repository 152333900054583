import { Component } from '@angular/core';

@Component({
  selector: 'app-training-and-support',
  templateUrl: './training-and-support.component.html',
  styleUrls: ['./training-and-support.component.scss']
})
export class TrainingAndSupportComponent {

}
