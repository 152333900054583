import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  _region: string;

  defaultConf = {
    defaultLocale: 'en',
    currency: "USD",
  }
  conf = {
    sa: {
      defaultLocale: 'ar-SA',
      currency: 'SR',
    },
    us: {
      defaultLocale: 'en-US',
      currency: 'USD',
    }
  }
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this.initRegion()
  }
  initRegion() {
    if (environment.production) {
      const domain = this.document.location.hostname.split('.');
      const TLD = domain[domain.length - 1]
      if (this.conf.hasOwnProperty(TLD)) this._region = TLD
    }
  }
  get region() {
    if (!this._region) this.initRegion()

    return this._region
  }
  get regionConf() {
    return this.conf[this.region] ?? this.defaultConf
  }

  get regionLocale() {
    return this.regionConf.defaultLocale
  }
  get regionCurrency() {
    return this.regionConf.currency
  }

  public getLocale(lang) {
    return lang + (this.region ? `-${this.region.toUpperCase()}` : '')
  }

  public getLang(locale) {
    return locale.split('-')[0]
  }
}
