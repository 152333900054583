import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { SourcingPlansService } from 'src/app/services/sourcing-plans.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sourcing-plans',
  templateUrl: './sourcing-plans.component.html',
  styleUrls: ['./sourcing-plans.component.scss'],
})
export class SourcingPlansComponent implements OnInit {
  selectedLanguage = this.translateService.currentLang;

  @Output() onSelect = new EventEmitter();
  handleSelect(plan) {
    this.onSelect.emit(plan);
  }

  sourcingPlansService = inject(SourcingPlansService);
  plansQuery = this.sourcingPlansService.list();

  pricingOption = 'annual';
  pricingOptions = [
    {
      type: 'monthly',
      title: { ar: 'ادفع حسب الاستخدام', en: 'Pay as you go' },
    },
    {
      type: 'annual',
      title: { ar: 'شهري', en: 'Monthly' },
    },
    {
      type: 'annual-upfront',
      title: { ar: 'سنوي', en: 'Annual' },
      description: '',
      discount: { ar: 'وفر ١٥٪', en: '- Save 15%' },
    },
  ];

  // features = {
  //   'position-at-a-time': (feature) =>
  //     `${feature.pivot.charges?.replace('.00', '')} position at a time`,
  //   'sourcing-requests-monthly': (feature) =>
  //     `${feature.pivot.charges?.replace('.00', '')} Sourcing Requests monthly`,
  //   'qualified-cvs-per-request': (feature) =>
  //     `Up to ${feature.pivot.charges?.replace(
  //       '.00',
  //       ''
  //     )} qualified CVs per request`,
  //   'configurable-kanban': (feature) => `Customizable ATS`,
  //   'job-description-generator': (feature) => `Job discerption generator`,
  // };
  parseIntFunc = parseInt;

  features = {
    'position-at-a-time': (feature, plan) =>
      this.parseIntFunc(feature.pivot.charges) === 1
        ? 'plans.features.position-at-a-time'
        : 'plans.features.simultaneous-sourcing-requests',
    'sourcing-requests-monthly': (feature, plan) =>
      plan.commitment === 'annual-upfront'
        ? 'plans.features.sourcing-requests-yearly'
        : 'plans.features.sourcing-requests-monthly',
    // 'qualified-cvs-per-request': (feature, plan) =>
    //   'plans.features.qualified-cvs-per-request',
    // 'configurable-kanban': (feature, plan) =>
    //   `plans.features.configurable-kanban`,
    'job-description-generator': (feature, plan) =>
      `plans.features.job-description-generator`,
    // 'global-access-to-talent': (feature, plan) =>
    //   `plans.features.global-access-to-talent`,
    'transferrable-sourcing-request-credits-from-month-to-moth': (feature, plan) => 'plans.features.transferrable-sourcing-request-credits-from-month-to-month'
  };
  get plans() {
    return this.plansRequest?.data?.data?.filter(
      (plan) => plan.commitment === this.pricingOption
    );
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translateService: TranslateService
  ) {
    translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });
  }

  plansRequest = {
    isLoading: true,
    isError: false,
    data: null,
  };
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.plansQuery.result$.subscribe((result) => {
        this.plansRequest = result;
      });
    }
  }
  isPluralPipDisabled(feature){
    return !['sourcing-requests-monthly'].includes(feature.name)
  }
}
