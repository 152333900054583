import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'arabicNumber',
  pure: false,
})
export class ArabicNumberPipe implements PipeTransform {
  subscriber;
  numbersObject: { [x: string]: string } = {
    '1': '١',
    '2': '٢',
    '3': '٣',
    '4': '٤',
    '5': '٥',
    '6': '٦',
    '7': '٧',
    '8': '٨',
    '9': '٩',
    '0': '٠',
  };
  constructor(
    private translateService: TranslateService,
    private _ref: ChangeDetectorRef
  ) {
    this.subscriber = this.translateService.onLangChange.subscribe(() => {
      this._ref.markForCheck();
    });
  }
  transform(n: number | string): string {
    if (n === null || n === undefined) return '';

    if (this.translateService.currentLang === 'ar') {
      n = n + ''; // to make it a string if it was a number
      let newString = '';
      for (let i = 0; i < n.length; i++) {
        if (this.numbersObject[n.charAt(i)])
          newString += this.numbersObject[n.charAt(i)];
        else newString += n.charAt(i);
      }

      return newString;
    }
    return n.toString();
  }
  onDestroy() {
    this.subscriber?.unsubscribe();
  }
}
