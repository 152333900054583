<div style="padding: 56.25% 0 0 0; position: relative">
  <iframe
    width="560"
    height="315"
    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    class="bg-blue-400"
    [ngClass]="{ 'bg-blue-400': !iframeLoaded, 'animate-pulse': !iframeLoaded }"
    (load)="iframeLoaded = true"
    [src]="safeVideoUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
</div>
