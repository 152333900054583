import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AirtableEmailService {
  private readonly API_URL = environment.airtable_api_url;
  private readonly API_KEY = environment.airtable_api_key;
  private readonly HEADERS = new HttpHeaders({
    Authorization: `Bearer ${this.API_KEY}`,
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {}

  createRecords(records: any[]) {
    return this.http.post(
      this.API_URL,
      { records: records },
      { headers: this.HEADERS }
    );
  }
}
