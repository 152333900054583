import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'priceFormat',
  pure: false,
})
export class PriceFormatPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: number | string, currency: string): string {
    const currentLang = this.translate.currentLang;
    const currencyLocalized = this.translate.instant(`currency.${currency}`);


    if (currentLang === 'ar') {
      if (currency === 'SAR') return value.toString() + ' ' + currencyLocalized;
    }
    return currencyLocalized + ' ' + value.toString();
  }
}
