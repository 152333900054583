<ng-helmet>
  <title>
    Uptal | Enterprise Recruitment
  </title>
</ng-helmet>

<div class="overflow-x-hidden">

  <app-home-default></app-home-default>
  <!--<app-home-sa *ngIf="region==='sa'"></app-home-sa>-->
</div>
