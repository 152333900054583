<ng-helmet>
  <title>
    Security & Privacy | Uptal
  </title>
</ng-helmet>
<div class="bg-white py-24 sm:py-8">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-3xl lg:text-center">
      <p
        class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
      >
        Security & Privacy
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Our commitment to data sovereignty, privacy, and customized deployment
        options ensures that your recruitment process is not only efficient but
        also fully compliant with the highest standards of data protection.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl
        class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
      >
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                data-slot="icon"
                class="w-6 h-6 text-white"
              >
                <path
                  fill-rule="evenodd"
                  d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            Servers are In Kingdom
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>
                Can be hosted on Cloud Infrastructure in Jeddah, Saudi Arabia
              </li>
              <li>
                Server location promotes faster data retrieval and lower latency
              </li>
              <li>
                Local hosting complies with local data protection and
                sovereignty laws
              </li>
            </ul>
          </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </div>
            We comply with local cyber security standards
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>Compliance with local regulations is our topmost priority</li>
              <li>
                We adhere to all guidelines by the National Data Management
                Office
              </li>
              <li>
                We ensures utmost respect and protection for personal data,
                guarding against misuse and unauthorized access
              </li>
            </ul>
          </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                data-slot="icon"
                class="w-6 h-6 text-white"
              >
                <path
                  d="M6 3a3 3 0 0 0-3 3v1.5a.75.75 0 0 0 1.5 0V6A1.5 1.5 0 0 1 6 4.5h1.5a.75.75 0 0 0 0-1.5H6ZM16.5 3a.75.75 0 0 0 0 1.5H18A1.5 1.5 0 0 1 19.5 6v1.5a.75.75 0 0 0 1.5 0V6a3 3 0 0 0-3-3h-1.5ZM12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM4.5 16.5a.75.75 0 0 0-1.5 0V18a3 3 0 0 0 3 3h1.5a.75.75 0 0 0 0-1.5H6A1.5 1.5 0 0 1 4.5 18v-1.5ZM21 16.5a.75.75 0 0 0-1.5 0V18a1.5 1.5 0 0 1-1.5 1.5h-1.5a.75.75 0 0 0 0 1.5H18a3 3 0 0 0 3-3v-1.5Z"
                />
              </svg>
            </div>
            Data can be isolated
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>
                Deployment of a strict data isolation strategy, ensuring your
                data is stored in a dedicated and secure environment
              </li>
              <li>
                Data access is strictly limited to your authorized personnel;
                our team does not have direct access
              </li>
              <li>
                Guarantee of confidentiality and protection from unauthorized
                access to your sensitive information
              </li>
            </ul>
          </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                data-slot="icon"
                class="w-6 h-6 text-white"
              >
                <path
                  d="M5.507 4.048A3 3 0 0 1 7.785 3h8.43a3 3 0 0 1 2.278 1.048l1.722 2.008A4.533 4.533 0 0 0 19.5 6h-15c-.243 0-.482.02-.715.056l1.722-2.008Z"
                />
                <path
                  fill-rule="evenodd"
                  d="M1.5 10.5a3 3 0 0 1 3-3h15a3 3 0 1 1 0 6h-15a3 3 0 0 1-3-3Zm15 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm2.25.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM4.5 15a3 3 0 1 0 0 6h15a3 3 0 1 0 0-6h-15Zm11.25 3.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM19.5 18a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            On premise deployment
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>
                We offer the flexibility to deploy our platform on your premises
              </li>
              <li>
                To ensure optimal functionality and service delivery of our SaaS
                platform, a secure connection to our server is required
              </li>
              <li>
                Our hybrid model gives you full control over your data while
                leveraging the advantages of our cloud services
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
