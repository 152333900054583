<div class="mx-auto max-w-6xl">
  <div class="mx-auto max-w-4xl text-center">
    <p id="pricing" class="text-2xl font-semibold tracking-tight sm:text-3xl">
      {{ "sourcing.Pricing_Options" | translate }}
    </p>
  </div>
  <p class="mx-auto mt-6 max-w-3xl text-center text-lg leading-8 text-gray-500">
    {{ "sourcing.Choose_Plan" | translate }}
  </p>

  <div *ngIf="plansRequest.isLoading"
    class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
    <div *ngFor="let plan of [1, 2, 3]" class="rounded-3xl p-6 xl:p-8 ring-1 ring-gray-200 bg-white">
      <div class="flex items-center justify-between gap-x-4">
        <h3 id="tier-enterprise" class="loading-gradient w-1/2 h-8 text-2xl font-medium leading-8 text-gray-900 pb-4">
        </h3>
      </div>
      <p class="loading-gradient w-full h-5 mt-4 text-sm leading-6 text-gray-600"></p>
      <p class="loading-gradient w-full h-5 mt-1 text-sm leading-6 text-gray-600"></p>
      <p class="w-2/3 h-12 mt-6 flex items-baseline gap-x-1">
        <span class="loading-gradient w-20 h-10 text-4xl font-bold tracking-tight text-gray-900"></span>
        <span class="loading-gradient w-16 h-10 text-sm font-semibold leading-6 text-gray-600"></span>
      </p>
      <button
        class="loading-gradient w-full h-12 mt-6 block rounded-md py-2 px-3 text-center text-sm font-medium leading-6"></button>
      <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
        <ng-container *ngFor="let feature of [1, 2, 3]">
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd" />
            </svg>
            <div class="loading-gradient flex-1 h-4"></div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div *ngIf="!plansRequest.isLoading && plansRequest.isError">
    <p class="text-center text-gray-500 text-sm">
      Failed to load plans, try again later
    </p>
  </div>

  <ng-container *ngIf="!plansRequest.isLoading && !plansRequest.isError">
    <div class="mt-10 flex justify-center">
      <fieldset
        class="bg-white grid grid-cols-3 gap-x-1 rounded-full p-1 text-center text-xs font-medium leading-5 ring-1 ring-inset ring-gray-200">
        <legend class="sr-only">Payment frequency</legend>
        <label *ngFor="let option of pricingOptions"
          class="cursor-pointer rounded-full px-0.5 md:px-2.5 py-1 md:hover:text-black md:hover:bg-blue-50 group flex items-center justify-center"
          (click)="pricingOption = option.type" [ngClass]="{ 'bg-primary text-white': pricingOption == option.type }">
          <input type="radio" name="frequency" value="monthly" class="sr-only" />
          <p>
            {{ option.title[selectedLanguage] }}
            <br class="md:hidden" />
            <span *ngIf="option.description" class="text-xs px-2 text-gray-500 md:group-hover:text-black font-light italic"
              [class.text-white]="pricingOption == option.type">({{ option.description[selectedLanguage] }})</span>
            <span *ngIf="option.discount" class="text-xs px-2 text-blue-500 md:group-hover:text-black font-extrabold"
              [class.text-white]="pricingOption == option.type">{{ option.discount[selectedLanguage] }}</span>
          </p>
        </label>
      </fieldset>
    </div>
    <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <div *ngFor="let plan of plans" class="rounded-3xl p-6 xl:p-8 ring-1 ring-gray-200 bg-white">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-freelancer" class="text-2xl font-medium leading-8 pb-4">
            {{ 'plans.'+plan.name | translate }}
          </h3>
        </div>
        <ng-container *[ngSwitch]="plan.name">
          <ng-container *ngSwitchCase="'Junior AI Recruiter'">
            <p class="mt-4 text-sm leading-6 text-gray-600">
              {{ "plans.Junior_description" | translate }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'Senior AI Recruiter'">
            <p class="mt-4 text-sm leading-6 text-gray-600">
              {{ "plans.Senior_description" | translate }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'Shared AI Recruiter'">
            <p class="mt-4 text-sm leading-6 text-gray-600">
              {{ "plans.Shared_description" | translate }}
            </p>
          </ng-container>
        </ng-container>
        <p class="mt-6 flex items-baseline gap-x-1">
          <span class="text-4xl font-bold tracking-tight">
            {{ plan.total | number | priceFormat: 'SAR' }}
          </span>
          <!-- TODO Better handling for the condition -->
          <span *ngIf="plan.name !== 'Shared AI Recruiter'" class="text-sm font-medium leading-6 text-gray-600">/ {{ "sourcing.month" | translate }}</span>
        </p>
        <div *ngIf="pricingOption === 'annual-upfront'" class="text-sm italic font-light mt-3 leading-6 text-gray-600">
          {{ "sourcing.Annual_Payment" | translate }}
        </div>
        <div *ngIf="pricingOption === 'annual'" class="text-sm italic font-light mt-3 leading-6 text-gray-600">
          {{ "sourcing.Monthly_Commitment" | translate }}
        </div>
        <div *ngIf="pricingOption === 'monthly'"
          class="invisible text-sm italic font-light mt-3 leading-6 text-gray-600">
          -
        </div>
        <button (click)="handleSelect(plan)" aria-describedby="tier-freelancer"
          class="w-full hire-ai-recruiter-plans-button mt-6 block rounded-md py-2 px-3 text-center text-lg font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary bg-primary text-white hover:bg-primary-dark">
          {{ "sourcing.Hire_AI_Recruiter" | translate }}
        </button>
        <!-- TODO Better handling for the condition -->
        <ul *ngIf="plan.name !== 'Shared AI Recruiter'" role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
          <ng-container *ngFor="let feature of plan.features">
            <li *ngIf="features[feature.name]" class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd" />
              </svg>
              {{
              features[feature.name]?.(feature, plan)
              |
              pluralTranslate:parseIntFunc(feature.pivot.charges):{skip:isPluralPipDisabled(feature)}
              |
              translate:{charges:parseIntFunc(feature.pivot.charges)} | arabicNumber
              }}
            </li>
          </ng-container>
        </ul>
        <ul *ngIf="plan.name === 'Shared AI Recruiter'" role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd" />
            </svg>
            {{ 'plans.features.single-sourcing-request'| translate }}
          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd" />
            </svg>
            {{ 'plans.features.request-may-take-up-to-2-weeks'| translate }}
          </li>
        </ul>
      </div>

      <div *ngIf="pricingOption != 'monthly'" class="rounded-3xl p-6 xl:p-8 ring-1 ring-gray-200 bg-white">
        <div class="flex items-center justify-between gap-x-4">
          <h3 id="tier-enterprise" class="text-2xl font-medium leading-8 pb-4">
            {{ "plans.Special Request" | translate }}

          </h3>
        </div>
        <p class="mt-4 text-sm leading-6 text-gray-600">
          {{ "sourcing.Tailored_Service" | translate }}
        </p>
        <p class="mt-6 flex items-baseline gap-x-1 invisible">
          <span class="text-4xl font-bold tracking-tight text-gray-900">-</span>
          <span class="text-sm font-semibold leading-6 text-gray-600">/</span>
        </p>
        <div class="invisible text-sm italic font-light mt-3 leading-6 text-gray-600">
          -
        </div>
        <a href="https://meetings-eu1.hubspot.com/faisal-alshareef" aria-describedby="tier-enterprise"
          class="mt-6 block contact-us-plans-button rounded-md py-2 px-3 text-center text-lg font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary bg-primary text-white hover:bg-blue-dark">
          {{ "sourcing.Contact_Us" | translate }}
        </a>
      </div>
    </div>
    <!-- <div class="px-6 py-4 mt-4 w-full rounded-2xl border border-gray-200">
      <div class="font-semibold">Comes with:</div>
      <div class="md:flex justify-between mt-3">
        <div class="py-3 flex gap-x-2">
          <div>
            <svg
              class="h-6 w-5 flex-none text-primary"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>Customizable ATS</div>
        </div>
        <div class="py-3 flex gap-x-2">
          <div>
            <svg
              class="h-6 w-5 flex-none text-primary"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>One-way video interviews</div>
        </div>
        <div class="py-3 flex gap-x-2">
          <div>
            <svg
              class="h-6 w-5 flex-none text-primary"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>Live interviews</div>
        </div>
      </div>
    </div> -->
  </ng-container>
</div>
