import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Component, HostListener } from '@angular/core';
import { LeavePageDialogComponent } from './leave-page-dialog/leave-page-dialog.component';
import { AiRecruiterDialogComponent } from '../dialogs/ai-recruiter-dialog/ai-recruiter-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ai-recruiter',
  templateUrl: './ai-recruiter.component.html',
  styleUrls: ['./ai-recruiter.component.scss'],
})
export class AiRecruiterComponent {
  mouseLeftPage = false;
  selectedLanguage = this.translateService.currentLang;

  stars = [
    { stars: [1, 1, 1, 1, 1], key: 'sourcing.Best_Candidates', text: 'Best candidates' },
    { stars: [1, 1, 1, 1, 1], key: 'sourcing.Super_Fast', text: 'Super fast' },
    {
      stars: [1, 1, 1, 1, 1],
      key: 'sourcing.Essential_Tool',
      text: "Can't hire without it",
    },
  ];
  starsSmall = [{ stars: [1, 1, 1, 1, 1], key: 'sourcing.Essential_Tool', text: "Can't hire without it" }];

  checks = [
    { en: 'Satisfaction guaranteed', ar: 'نضمن رضاكم' },
    { en: 'Fast and reliable', ar: 'سريع وموثوق' },
    { en: 'Easy to use', ar: 'سهل الاستخدام' },
  ];

  trustedCompanies = [
    {
      src: 'assets/images/home/company-logos/gaga-logo.png',
      height: '50',
      w: '100',
      h: '100',
    },
    {
      src: 'assets/images/home/company-logos/hobby-district-logo.jpeg',
      height: '50',
      w: '100',
      h: '100',
    },
    // {
    //   src: 'assets/images/home/company-logos/hareer-logo.jpeg',
    //   height: '80',
    //   w: '328',
    //   h: '251',
    // },
    {
      src: 'assets/images/home/company-logos/qparts-logo.jpg',
      height: '50',
      w: '328',
      h: '251',
    },
    {
      src: 'assets/images/home/company-logos/sets-logo.webp',
      height: '50',
      w: '160',
      h: '100',
    },
    {
      src: 'assets/images/home/company-logos/savola-logo.webp',
      height: '50',
      w: '343',
      h: '147',
    },

    {
      src: 'assets/images/home/company-logos/asas-logo.webp',
      height: '80',
      w: '328',
      h: '251',
    },
    {
      src: 'assets/images/home/company-logos/ntdp-logo.webp',
      height: '50',
      w: '100',
      h: '100',
    },
    {
      src: 'assets/images/home/company-logos/65-logo.png',
      height: '50',
      w: '100',
      h: '100',
    },
    {
      src: 'assets/images/home/company-logos/blue-assembly-logo.webp',
      height: '40',
      w: '100',
      h: '100',
    },
    {
      src: 'assets/images/home/company-logos/unifonic-logo.svg',
      height: '25',
      w: '183',
      h: '25',
    },
  ];

  faqs = [
    {
      question: 'sourcing.AI_Recruiter_Info',
      answer: 'sourcing.AI_Recruiter_Description',
      open: false,
    },
    {
      question: 'sourcing.AI_Recruiter_Sourcing',
      answer: 'sourcing.AI_Recruiter_Sourcing_Description',
      open: false,
    },
    {
      question: 'sourcing.Sourcing_Speed',
      answer: 'sourcing.Sourcing_Speed_Description',
      open: false,
    },
    {
      question: 'sourcing.Payment_Options',
      answer: 'sourcing.Payment_Options_Description',
      open: false,
    },
    {
      question: 'sourcing.Risk_Free_Policy',
      answer: 'sourcing.Risk_Free_Policy_Description',
      open: false,
    },
    {
      question: 'sourcing.Cancellation_Policy',
      answer: 'sourcing.Cancellation_Policy_Description',
      open: false,
    },
    {
      question: 'sourcing.Data_Security',
      answer: 'sourcing.Data_Security_Description',
      open: false,
    },
    {
      question: 'sourcing.System_Integration',
      answer: 'sourcing.System_Integration_Description',
      open: false,
    },
  ];

  constructor(
    private matDialog: MatDialog,
    private translateService: TranslateService
  ) {
    translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });
  }

  onSelectPlan(plan) {
    const subdomain = environment.server === 'dev' ? 'dev' : 'app';
    window.open(
      `https://${subdomain}.uptal.com/register?sourcing-plan=${plan.id}`,
      '_self'
    );
  }

  getStartedUrl() {
    const subdomain = environment.server === 'dev' ? 'dev' : 'app';
    return `https://${subdomain}.uptal.com/register?sourcing-plan=get-started`;
  }

  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    if (!this.mouseLeftPage) {
      this.openLeavePageDialog();
    }
    this.mouseLeftPage = true;
  }

  openLeavePageDialog() {
    const dialogRef = this.matDialog.open(LeavePageDialogComponent, {
      data:{
        url: this.getStartedUrl()
      }
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openVideo(): void {
    this.matDialog.open(AiRecruiterDialogComponent, {
      width: '90%',
      maxWidth: '1024px',
      panelClass: 'dialog-no-px',
    });
  }
}
