<ng-helmet>
  <title>
    Page not Found 404 | Uptal
  </title>
  <meta name="robots" content="noindex" />
</ng-helmet>
<main
  class="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8"
>
  <div class="text-center">
    <p class="text-base font-semibold bt-text-red">404</p>
    <h1
      class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
    >
      Page not found
    </h1>
    <p class="mt-6 text-base leading-7 text-gray-600">
      Sorry, we couldn’t find the page you’re looking for.
    </p>
    <div class="mt-10 flex items-center justify-center gap-x-6">
      <a
        routerLink="/"
        class="rounded-md bt-bg-red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
        >Go back home</a
      >
      <a
        href="mailto:info@baseteam.io"
        class="text-sm font-semibold text-gray-900"
        >Contact support <span aria-hidden="true">&rarr;</span></a
      >
    </div>
  </div>
</main>
