<ng-helmet>
  <title>
    Terms and Conditions | Uptal
  </title>
</ng-helmet>
<main-header [darkMode]="true"></main-header>
<section class="max-w-screen-xl mx-auto px-8 pt-28 pb-12">

  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'><strong>Terms of Service</strong></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>These Terms of Service (&quot;Agreement&quot;)
    govern your use of the Uptal website and any related services (collectively, the &quot;Service&quot;). By using the
    Service, you agree to be bound by this Agreement. If you do not agree to these terms, please do not use the Service.
  </p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="1" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Use of the
        Service</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal provides a self-recorded interview
    software that allows companies to screen candidates through artificial intelligence technology. You may use the
    Service only for lawful purposes and in accordance with this Agreement. You may not use the Service for any illegal
    or unauthorized purpose.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="2" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Eligibility</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>You represent and warrant that you are at least
    18 years of age and have the legal capacity to enter into this Agreement. If you are using the Service on behalf of
    a company or organization, you represent and warrant that you are authorized to bind such entity to this Agreement.
  </p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="3" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Privacy</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal respects your privacy and is committed to
    protecting it. Our Privacy Policy, which is incorporated into this Agreement by reference, explains how we collect,
    use, and disclose information about you.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="4" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Intellectual
        Property</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>The Service, including all software, graphics,
    images, and text, and the selection and arrangement thereof, is owned by Uptal or its licensors and is protected by
    copyright and other intellectual property laws. You may not use any content of the Service without our prior written
    consent.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="5" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Disclaimer of
        Warranties</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>THE SERVICE IS PROVIDED &quot;AS IS&quot;
    WITHOUT WARRANTY OF ANY KIND. UPTAL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. UPTAL DOES NOT
    WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="6" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Limitation of
        Liability</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>IN NO EVENT SHALL UPTAL BE LIABLE FOR ANY
    INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SERVICE,
    WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF UPTAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
    DAMAGES. UPTAL&apos;S LIABILITY UNDER THIS AGREEMENT SHALL NOT EXCEED THE AMOUNT PAID BY YOU FOR THE SERVICE.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="7" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'>
      <em>Indemnification</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>You agree to indemnify, defend, and hold Uptal
    harmless from any and all claims, damages, losses, costs, and expenses (including reasonable attorneys&apos; fees)
    arising out of or in connection with your use of the Service or your breach of this Agreement.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="8" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Governing
        Law</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>This Agreement shall be governed by and
    construed in accordance with the laws of Saudi Arabia, without giving effect to any principles of conflicts of law.
    Any legal action or proceeding arising out of or in connection with this Agreement shall be brought in the courts of
    Riyadh, Saudi Arabia.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="9" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'>
      <em>Modification</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal reserves the right to modify this
    Agreement at any time. Your continued use of the Service after any such modification shall constitute your
    acceptance of the modified Agreement.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="10" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Entire
        Agreement</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>This Agreement, including the Privacy Policy,
    constitutes the entire agreement between you and Uptal with respect to the Service and supersedes all prior or
    contemporaneous communications and proposals, whether oral or written, between you and Uptal.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>If you have any questions about this Agreement
    or the Service, please contact us at support@uptal.com.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'><strong>Terms and Conditions</strong></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Welcome to Uptal! These Terms and Conditions
    govern your use of our services and website. By accessing and using our services and website, you agree to these
    Terms and Conditions. If you do not agree to these Terms and Conditions, you may not use our services or website.
  </p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="1" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Definitions</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><em><span
        style="color:black;">&nbsp;</span></em></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>In these Terms and Conditions:</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ul style="list-style-type: undefined;margin-left:0in;">
    <li><span style="color:black;">&quot;Uptal&quot;, &quot;we&quot;, &quot;our&quot;, or &quot;us&quot; refers to Uptal
        Company.</span></li>
    <li><span style="color:black;">&quot;Services&quot; means the recruiting software services provided by Uptal.</span>
    </li>
    <li><span style="color:black;">&quot;Website&quot; means the website located at https://www.uptal.com/ and any
        related Uptal websites.</span></li>
  </ul>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="2" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Use of
        Services</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><em><span
        style="color:black;">&nbsp;</span></em></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>You may use our Services only for lawful
    purposes and in accordance with these Terms and Conditions. You agree not to use our Services:</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ul style="list-style-type: undefined;margin-left:0in;">
    <li><span style="color:black;">In any way that violates any applicable Saudi Arabian or international law or
        regulation.</span></li>
    <li><span style="color:black;">To engage in any conduct that restricts or inhibits anyone&apos;s use or enjoyment of
        our Services or Website, or which, as determined by us, may harm Uptal or users of our Services or Website, or
        expose them to liability.</span></li>
    <li><span style="color:black;">To use our Services in any manner that could disable, overburden, damage, or impair
        our Services or Website or interfere with any other party&apos;s use of our Services or Website, including their
        ability to engage in real time activities through our Services or Website.</span></li>
    <li><span style="color:black;">To use any robot, spider, or other automatic device, process, or means to access our
        Services or Website for any purpose, including monitoring or copying any of the material on our Services or
        Website.</span></li>
    <li><span style="color:black;">To use any manual process to monitor or copy any of the material on our Services or
        Website or for any other unauthorized purpose without our prior written consent.</span></li>
    <li><span style="color:black;">To use any device, software, or routine that interferes with the proper working of
        our Services or Website.</span></li>
    <li><span style="color:black;">To introduce any viruses, Trojan horses, worms, logic bombs, or other material which
        is malicious or technologically harmful.</span></li>
    <li><span style="color:black;">To attempt to gain unauthorized access to, interfere with, damage, or disrupt any
        parts of our Services or Website, the server on which our Services or Website is stored, or any server,
        computer, or database connected to our Services or Website.</span></li>
    <li><span style="color:black;">To attack our Services or Website via a denial-of-service attack or a distributed
        denial-of-service attack.</span></li>
  </ul>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="3" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;border:none;'><span
        style="color:     black;">User Accounts</span></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>To access and use certain features of our
    Services, you may need to register for an account with us. When you register for an account, you agree to:</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ul style="list-style-type: undefined;margin-left:0in;">
    <li><span style="color:black;">Provide accurate, current, and complete information about yourself as prompted by the
        registration form.</span></li>
    <li><span style="color:black;">Maintain and promptly update such information to keep it accurate, current, and
        complete.</span></li>
    <li><span style="color:black;">Keep your login credentials, including your password, confidential and not disclose
        them to anyone else.</span></li>
    <li><span style="color:black;">Be responsible for all activities that occur under your account.</span></li>
  </ul>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="4" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Payment</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><em><span
        style="color:black;">&nbsp;</span></em></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal provides a free trial of our Services.
    After the trial period, users may choose to continue to use our Services by paying the applicable fees. By using our
    paid Services, you agree to pay all applicable fees and charges, including applicable taxes.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="5" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Intellectual
        Property</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'><em>&nbsp;</em></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>All content and materials provided on the Uptal
    website, including but not limited to text, graphics, logos, icons, images, audio clips, video clips, data
    compilations, and software, are the property of Uptal or its content suppliers and protected by Saudi and
    international copyright laws.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>You may not copy, reproduce, modify, republish,
    upload, post, transmit, or distribute any content or materials from the Uptal website without the prior written
    consent of Uptal or the appropriate copyright owner.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal grants you a limited, non-exclusive,
    non-transferable, revocable license to access and use the Uptal website for your personal and non-commercial use
    only. This license does not include the right to modify or download any content or materials from the Uptal website
    except as necessary to view the content for your personal use.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="6" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Limitation of
        Liability</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal shall not be liable for any damages,
    including without limitation, direct, indirect, incidental, consequential, punitive, or exemplary damages, arising
    out of or relating to these Terms and Conditions or the use of the Uptal website.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal shall not be liable for any damages,
    including without limitation, damages to, or viruses that may infect, your computer equipment or other property on
    account of your access to, use of, or browsing in the Uptal website or your downloading of any materials, data,
    text, images, video, or audio from the Uptal website.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="7" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'>
      <em>Indemnification</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><em><span
        style="color:black;">&nbsp;</span></em></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>You agree to indemnify, defend, and hold
    harmless Uptal, its officers, directors, employees, agents, licensors, and suppliers from and against all losses,
    expenses, damages, and costs, including reasonable attorneys&apos; fees, resulting from any violation of these Terms
    and Conditions or any activity related to your use of the Uptal website (including but not limited to negligent or
    wrongful conduct).</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="8" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Termination</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><em><span
        style="color:black;">&nbsp;</span></em></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal may terminate these Terms and Conditions
    and your access to the Uptal website at any time without notice. In the event of termination, you are no longer
    authorized to access the Uptal website, and the restrictions imposed on you with respect to the content and
    materials on the Uptal website, as well as the disclaimers and limitations of liabilities set forth in these Terms
    and Conditions, shall survive.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="9" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Governing
        Law</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>These Terms and Conditions shall be governed by
    and construed in accordance with the laws of Saudi Arabia, without giving effect to any principles of conflicts of
    law. You agree that any action at law or in equity arising out of or relating to these Terms and Conditions or the
    use of the Uptal website shall be filed only in the courts located in Riyadh, Saudi Arabia, and you hereby consent
    and submit to the personal jurisdiction of such courts for the purposes of litigating any such action.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="10" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Entire
        Agreement</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>These Terms and Conditions constitute the
    entire agreement between you and Uptal with respect to the use of the Uptal website and supersede all prior or
    contemporaneous communications and proposals, whether oral or written, between you and Uptal with respect to such
    use. If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining
    provisions shall be enforced to the fullest extent possible, and the remaining provisions of these Terms and
    Conditions shall remain in full force and effect.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'><strong>Privacy Policy</strong></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>At Uptal, we respect your privacy and are
    committed to protecting your personal data. This Privacy Policy explains how we collect, use, and disclose personal
    data when you use our website or services.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="1" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Personal Data We
        Collect</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>When you use Uptal, we may collect the
    following personal data:</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ul style="list-style-type: undefined;margin-left:0in;">
    <li><span style="color:black;">Contact information, such as your name and email address</span></li>
    <li><span style="color:black;">Professional information, such as your job title and company name</span></li>
    <li><span style="color:black;">Login information, such as your username and password</span></li>
    <li><span style="color:black;">Usage information, such as your IP address, browser type, and device
        information</span></li>
    <li><span style="color:black;">Information you provide to us when you use our services, such as interview
        recordings, candidate resumes, and job postings</span></li>
  </ul>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="2" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>How We Use
        Personal Data</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>We use personal data to provide our services to
    you, including:</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ul style="list-style-type: undefined;margin-left:0in;">
    <li><span style="color:black;">Creating and managing your account</span></li>
    <li><span style="color:black;">Providing customer support</span></li>
    <li><span style="color:black;">Processing payments</span></li>
    <li><span style="color:black;">Analyzing and evaluating candidate interviews</span></li>
    <li><span style="color:black;">Providing hiring recommendations</span></li>
    <li><span style="color:black;">Personalizing your experience with Uptal</span></li>
    <li><span style="color:black;">We may also use personal data for marketing purposes, such as sending you promotional
        emails and newsletters. You can opt-out of receiving these communications at any time.</span></li>
  </ul>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="3" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>How We Share
        Personal Data</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>We may share personal data with third-party
    service providers who help us operate our business, such as payment processors and cloud storage providers. We may
    also share personal data with our partners and affiliates for marketing purposes.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>We may disclose personal data if required by
    law or in response to legal process, such as a court order or subpoena. We may also disclose personal data if we
    believe it is necessary to protect the rights, property, or safety of Uptal, our users, or the public.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="4" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Your Rights</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.25in;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>You have the right to access, correct, and
    delete your personal data. You can do so by contacting us at support@uptal.com. You also have the right to object to
    or restrict the processing of your personal data.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="5" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Data
        Retention</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>We will retain your personal data for as long
    as necessary to provide our services to you and as required by applicable law. We may also retain and use your
    personal data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="6" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Security</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><em><span
        style="color:black;">&nbsp;</span></em></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>We take reasonable measures to protect your
    personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or
    method of electronic storage is 100% secure, and we cannot guarantee the absolute security of your personal data.
  </p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="7" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Changes to This
        Privacy Policy</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>We may update this Privacy Policy from time to
    time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review
    this Privacy Policy periodically for any changes.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="8" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Contact Us</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>If you have any questions about this Privacy
    Policy, please contact us at <a href="mailto:support@uptal.com"><span
        style="color:#0563C1;">support@uptal.com</span></a>.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'><strong>Affiliate Agreement</strong></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>This Affiliate Agreement (the
    &quot;Agreement&quot;) is made and entered into by and between Uptal (&quot;Uptal&quot;) and you
    (&quot;Affiliate&quot;), effective as of the date you sign up to become an affiliate of Uptal.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="1" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Agreement
        Overview</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>The purpose of this Agreement is to establish a
    working relationship between Uptal and the Affiliate. Under this Agreement, the Affiliate will be paid a commission
    for each referral of a new customer to Uptal.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="2" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Affiliate
        Requirements</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>To become an affiliate of Uptal, the Affiliate
    must:</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ul style="list-style-type: undefined;margin-left:0in;">
    <li><span style="color:black;">Agree to the terms and conditions of this Agreement.</span></li>
    <li><span style="color:black;">Have an active account with Uptal.</span></li>
    <li><span style="color:black;">Promote Uptal on the Affiliate&apos;s website, blog, or social media channels.</span>
    </li>
    <li><span style="color:black;">Commission</span></li>
    <li><span style="color:black;">For each new customer that is referred to Uptal through the Affiliate&apos;s unique
        referral link and who completes a purchase of Uptal services, the Affiliate will receive a commission of 10% of
        the total purchase price.</span></li>
  </ul>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="3" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Payment</em>
    </li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal will pay commissions to the Affiliate on
    a monthly basis. The commission payments will be made through the Affiliate&apos;s Uptal account.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="4" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Promotion
        Restrictions</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>The Affiliate agrees not to engage in any of
    the following activities:</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ul style="list-style-type: undefined;margin-left:0in;">
    <li><span style="color:black;">Spamming or unsolicited emailing of Uptal referral links.</span></li>
    <li><span style="color:black;">Promoting Uptal in a way that is deceptive or misleading.</span></li>
    <li><span style="color:black;">Using Uptal&apos;s branding or intellectual property without Uptal&apos;s
        permission.</span></li>
    <li><span style="color:black;">Term and Termination</span></li>
    <li><span style="color:black;">This Agreement will remain in effect until terminated by either party. Either party
        may terminate this Agreement at any time, with or without cause, by providing written notice to the other
        party.</span></li>
  </ul>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="5" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'>
      <em>Confidentiality</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;margin-left:.5in;border:none;'><span
      style="color:black;">&nbsp;</span></p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>The Affiliate agrees to keep confidential any
    information provided by Uptal that is not publicly available.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="6" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Governing
        Law</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>This Agreement will be governed by and
    construed in accordance with the laws of Saudi Arabia.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="7" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Limitation of
        Liability</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>Uptal will not be liable to the Affiliate for
    any special, indirect, or consequential damages arising out of or in connection with this Agreement.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <ol style="margin-bottom:0in;margin-top:0in;" start="8" type="1">
    <li style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;color:black;border:none;'><em>Entire
        Agreement</em></li>
  </ol>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>This Agreement constitutes the entire agreement
    between Uptal and the Affiliate and supersedes all prior agreements and understandings, whether written or oral,
    relating to the subject matter of this Agreement.</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
  <p style='margin:0in;font-size:16px;font-family:"Calibri",sans-serif;'>By signing up to become an affiliate of Uptal,
    the Affiliate agrees to be bound by the terms and conditions of this Agreement.</p>

</section>



<main-footer></main-footer>
