import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { RegionService } from '../region.service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { translateBrowserLoaderFactory } from '../translate-browser.loader';
import { TransferState } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie';

@NgModule({
  imports: [
    HttpClientModule,
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  storageKey = 'uptal-locale';
  constructor(
    private translate: TranslateService,
    private regionService: RegionService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    const langs = ['en', 'ar'];
    this.translate.addLangs(langs);
    const lang = this.getLocale(); //this.regionService.regionLocale ?? 'en'
    this.translate.use(langs.includes(lang) ? lang : 'en');
    this.handleLangChange(lang);


    this.translate.onLangChange.subscribe((event) => {
      this.handleLangChange(event.lang);
      if (isPlatformBrowser(this.platformId)){
        const url = new URL(this.document.location.href);
        url.searchParams.set('lang', event.lang);
        window.history.pushState(null, '', url.toString());
      }
    });

  }

  private get cookiesOptions() {
    const expirationData = new Date();
    expirationData.setFullYear(expirationData.getFullYear() + 1);
    return {
      expires: expirationData,
    };
  }

  handleLangChange(_lang) {
    const lang = this.regionService.getLang(_lang);
    let ltrrtl = 'ltr';
    if (lang === 'ar') ltrrtl = 'rtl';
    else ltrrtl = 'ltr';

    this.document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    this.document.getElementsByTagName('body')[0].setAttribute('dir', ltrrtl);
    this.saveLocale(_lang);
  }

  saveLocale(locale) {
    if (isPlatformBrowser(this.platformId))
      this.cookieService.put(this.storageKey, locale, {
        ...this.cookiesOptions,
      });
  }

  getLocale() {
    let browserLocale = 'en';
    if (isPlatformBrowser(this.platformId))
      browserLocale = window.navigator.language?.split('-')[0] ?? 'en';
    const queryParams = new URLSearchParams(this.document.location.search);
    return (
      queryParams.get('lang') ??
      this.cookieService.get(this.storageKey) ??
      browserLocale
    );
  }
}
