import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { I18nModule } from './i18n/i18n.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeSaComponent } from './home/home-sa/home-sa.component';
import { HomeDefaultComponent } from './home/home-default/home-default.component';
import { PricingComponent } from './pricing/pricing.component';
import { CookieModule } from 'ngx-cookie';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInterceptor } from './http-client.interceptor';
import { HeroAnimationComponent } from './home/hero-animation/hero-animation.component';
import { NgChartsModule } from 'ng2-charts';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FeaturesComponent } from './features/features.component';
import { DashboardDemoLayoutComponent } from './layouts/dashboard-demo-layout/dashboard-demo-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CandidatesDemoComponent } from './demos/candidates-demo/candidates-demo.component';
import { CandidateInterviewDemoComponent } from './demos/candidates-demo/candidate-interview-demo/candidate-interview-demo.component';
import { CandidateTranscriptDemoComponent } from './demos/candidates-demo/candidate-transcript-demo/candidate-transcript-demo.component';
import { CandidateAnalysisDemoComponent } from './demos/candidates-demo/candidate-analysis-demo/candidate-analysis-demo.component';
import { CandidateHistoryDemoComponent } from './demos/candidates-demo/candidate-history-demo/candidate-history-demo.component';
import { CandidateCommentDemoComponent } from './demos/candidates-demo/candidate-history-demo/candidate-comment-demo/candidate-comment-demo.component';
import { RegionService } from './region.service';
import { RegionalDomainService } from './regional-domain.service';
import { SelectLanguageModule } from './select-language/select-language.module';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxAnimatedCounterModule } from '@bugsplat/ngx-animated-counter';
import { AcceleratedHiringSectionComponent } from './home/accelerated-hiring-section/accelerated-hiring-section.component';
import { AiHeadhuntingSectionComponent } from './home/features/ai-headhunting-section/ai-headhunting-section.component';
import { AiCandidateSourcingSectionComponent } from './home/features/ai-candidate-sourcing-section/ai-candidate-sourcing-section.component';
import { AiResumeEvaluationSectionComponent } from './home/features/ai-resume-evaluation-section/ai-resume-evaluation-section.component';
import { AiPoweredInterviewsSectionComponent } from './home/features/ai-powered-interviews-section/ai-powered-interviews-section.component';
import { FeaturesLayoutComponent } from './home/features/features-layout/features-layout.component';
import { HowItWorksVideoComponent } from './how-it-works-video/how-it-works-video.component';
import { PressComponent } from './home/features/press/press.component';
import { ContactUsComponent } from './home/features/contact-us/contact-us.component';
import { TrainingAndSupportComponent } from './home/features/training-and-support/training-and-support.component';
import { CustomizationComponent } from './home/features/customization/customization.component';
import { EventsComponent } from './home/features/events/events.component';
import { SecurityAndPrivacyComponent } from './home/features/security-and-privacy/security-and-privacy.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { NgHelmetModule } from 'ng-helmet';
import { SourcingEarlyAccessComponent } from './sourcing-early-access/sourcing-early-access.component';
import { AiRecruiterComponent } from './ai-recruiter/ai-recruiter.component';
import { SourcingPlansComponent } from './sourcing-plans/sourcing-plans.component';
import { provideQueryClientOptions } from '@ngneat/query';
import { SubscribeDirective } from '@ngneat/subscribe';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { LeavePageDialogComponent } from './ai-recruiter/leave-page-dialog/leave-page-dialog.component';
import { AiRecruiterDialogComponent } from './dialogs/ai-recruiter-dialog/ai-recruiter-dialog.component';
import { CountDownComponent } from './components/count-down/count-down.component';
import { ArabicNumberPipe } from './pipes/arabic-number.pipe';
import { PluralTranslatePipe } from './pipes/plural-translate.pipe';
import { PriceFormatPipe } from './pipes/price-format.pipe';

export function initializeServices(
  regionService: RegionService,
  regionalDomainService: RegionalDomainService
) {
  return () => {};
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    HomeSaComponent,
    HomeDefaultComponent,
    PricingComponent,
    HeroAnimationComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    FeaturesComponent,
    DashboardDemoLayoutComponent,
    CandidatesDemoComponent,
    CandidateInterviewDemoComponent,
    CandidateTranscriptDemoComponent,
    CandidateAnalysisDemoComponent,
    CandidateHistoryDemoComponent,
    CandidateCommentDemoComponent,
    AcceleratedHiringSectionComponent,
    AiHeadhuntingSectionComponent,
    AiCandidateSourcingSectionComponent,
    AiResumeEvaluationSectionComponent,
    AiPoweredInterviewsSectionComponent,
    FeaturesLayoutComponent,
    HowItWorksVideoComponent,
    PressComponent,
    ContactUsComponent,
    TrainingAndSupportComponent,
    CustomizationComponent,
    EventsComponent,
    SecurityAndPrivacyComponent,
    SourcingEarlyAccessComponent,
    AiRecruiterComponent,
    SourcingPlansComponent,
    LandingHeaderComponent,
    LeavePageDialogComponent,
    AiRecruiterDialogComponent,
    CountDownComponent,
    ArabicNumberPipe,
    PluralTranslatePipe,
    PriceFormatPipe,
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeServices,
      deps: [RegionalDomainService, RegionService],
      multi: true,
    },
    provideQueryClientOptions({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    }),
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CookieModule.withOptions(),
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    I18nModule,
    SelectLanguageModule,
    FooterModule,
    HeaderModule,
    RouterModule,
    NgChartsModule,
    MatCardModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    NgxAnimatedCounterModule,
    SubscribeDirective,
    NgxGoogleAnalyticsModule.forRoot(environment.google_analytics_tag),
    NgxGoogleAnalyticsRouterModule,
    NgHelmetModule
  ],
})
export class AppModule {}
