import { Component } from '@angular/core';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss'],
})
export class PressComponent {
  brandAssets = [
    {
      name: 'Uptal Logo',
      image: 'assets/images/home/brand-assets/uptal-logo.png',
      downloadLink:
        'https://uptal-public-files.s3.eu-west-1.amazonaws.com/brand-assets/Uptal_Logo.pdf',
    },
  ];

  downloadFile(link) {
    window.open(link, '_blank');
  }
}
