import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { RegionalDomainService } from '../regional-domain.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  mobileMenuOpen = false;
  showFixedMenu = false;
  @Input() darkMode = false;

  menuItems = [];
  items = [
    {
      title: 'Solutions',
      type: 'menu',
      items: [
        // {
        //   title: 'AI Candidate Sourcing',
        //   fragment: null,
        //   routerLink: '/features/ai-candidate-sourcing',
        // },
        {
          title: 'Resume Evaluation',
          fragment: 'features',
          routerLink: '/',
        },
        {
          title: 'Interviews',
          fragment: 'features',
          routerLink: '/',
        },
        {
          title: 'Technical Assessments',
          fragment: 'additional-features',
          routerLink: '/',
        },
        {
          title: 'Psychometric Assessments',
          fragment: 'additional-features',
          routerLink: '/',
        },
        {
          title: 'Headhunting',
          fragment: 'features',
          routerLink: '/',
        },
      ],
    },
    {
      title: 'Integrations',
      type: 'link',
      fragment: 'integrations',
      routerLink: '/',
    },
    // {
    //   title: 'Reports',
    //   type: 'link',
    //   fragment: 'reports',
    //   routerLink: '/',
    // },
    {
      title: 'Automation',
      type: 'link',
      fragment: 'additional-features',
      routerLink: '/',
    },
    {
      title: 'Training & Support',
      type: 'link',
      fragment: null,
      routerLink: '/training-and-support',
    },
    {
      title: 'Customization',
      type: 'link',
      fragment: null,
      routerLink: '/customization',
    },
    {
      title: 'About Us',
      type: 'link',
      fragment: null,
      routerLink: '/about-us',
    },
  ];
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private regionalDomainService: RegionalDomainService
  ) {}

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(el) {
    this.showFixedMenu = window.scrollY > 20;
  }

  isMdOrLarger() {
    if (isPlatformBrowser(this.platformId)) {
      return window?.innerWidth >= 768; // Tailwind's default breakpoint for 'md' is 768px
    }
    return true;
  }

  get domain() {
    const baseURL = this.regionalDomainService.domain;
    if (!baseURL) return '';

    return `${baseURL}${this.document.location.pathname}`;
  }

  getStartedUrl() {
    const subdomain = environment.server === 'dev' ? 'dev' : 'app';
    return `https://${subdomain}.uptal.com/register?sourcing-plan=get-started`;
  }
}
