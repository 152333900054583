import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { environment } from 'src/environments/environment';
import { GeolocationService } from './geolocation.service';
import { RegionService } from './region.service';

@Injectable({
  providedIn: 'root'
})
export class RegionalDomainService {
  private storageKey = environment.server === 'prod' ? "uptal_redirected" : "dev_uptal_redirected"
  domain=''
  domains = {
    'sa': "https://uptal.sa",
  }
  constructor(
    private regionService: RegionService,
    private cookieService: CookieService,
    private geolocationService: GeolocationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) this.handleRedirect()
  }

  handleRedirect() {

    this.geolocationService.getGeolocation().subscribe(
      (geolocation: any) => {
        const countryCode = (geolocation.countryCode as string)?.toLocaleLowerCase()
        if (countryCode !== this.regionService.region) {
          const domain = this.domains[countryCode]
          if (domain) this.domain = domain
          else if(this.regionService.region) this.domain = 'https://uptal.com'
        }
      },
      (err) => console.log(err)
    )
  }

  private get cookiesOptions() {
    const expirationData = new Date()
    expirationData.setFullYear(expirationData.getFullYear() + 1)
    return {
      expires: expirationData,
    }
  }
  saveCookie() {
    this.cookieService.put(this.storageKey, 'redirected', { ...this.cookiesOptions })
  }
  getCookie() {
    return !!this.cookieService.get(this.storageKey)
  }
}
