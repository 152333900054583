import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-candidate-sourcing-section',
  templateUrl: './ai-candidate-sourcing-section.component.html',
  styleUrls: ['./ai-candidate-sourcing-section.component.scss']
})
export class AiCandidateSourcingSectionComponent {

}
