import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { RegionService } from '../region.service';

@Component({
  selector: 'select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent implements OnInit {
  @Input('className') className = '';
  @Input() fullSize: boolean = true;
  @Input() color: string = 'white';
  @Input('allowedLocales')
  allowedLocales: Array<string> = ['en', 'ar'];
  languages = {
    en: 'English',
    es: 'Español',
    fr: 'Français',
    pt: 'Português',
    zh: '中文',
    ar: 'العربية',
    ru: 'Русский',
    de: 'Deutsch',
    it: 'Italiano',
    ja: '日本語',
    ko: '한국어',
    nl: 'Nederlands',
    tr: 'Türkçe',
    vi: 'Tiếng Việt',
  };

  constructor(
    public translate: TranslateService,
    public regionService: RegionService
  ) {}

  ngOnInit(): void {}

  get currentLang() {
    return this.languages[this.translate.currentLang.split('-')[0]];
  }

  filter(langs: Array<string>): Array<string> {
    return langs.filter(
      (lang) => !this.allowedLocales || this.allowedLocales.includes(lang)
    );
  }
  onLanguageSelect(lang) {
    this.translate.use(this.regionService.getLocale(lang));
  }
  trackBy(index, _) {
    return index;
  }
}
