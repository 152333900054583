import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';

declare var hbspt;

@Component({
  selector: 'app-sourcing-early-access',
  templateUrl: './sourcing-early-access.component.html',
  styleUrls: ['./sourcing-early-access.component.scss'],
})
export class SourcingEarlyAccessComponent implements AfterViewInit, OnDestroy {
  iframeLoaded = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      hbspt.forms.create({
        region: 'eu1',
        portalId: '139532807',
        formId: '9cdaf9b3-1e4f-4bbe-a1d0-87f72c6ce33e',
        target: '#sourcing-hubspot-form',
      });
    }
  }

  ngOnDestroy(): void {}
}
