<ng-helmet>
  <title>
    Customization | Uptal
  </title>
</ng-helmet>
<div class="w-full"></div>
<div class="w-full mx-auto">
  <div class="w-full py-8 max-w-3xl mx-auto text-center">
    <div class="text-4xl font-light">
      Tailored Solutions for Your Unique Needs
    </div>
    <div class="py-8">
      <div class="text-start py-8 text-gray-500 text-lg">
        For those with specific requirements, we invite you to connect with us
        directly. Let's schedule a call to discuss how Uptal can customize our
        AI-driven services to align perfectly with your unique business
        requirements and objectives.
      </div>
      <div class="w-full flex justify-center">
        <a
          routerLink="/register"
          target="_blank"
          class="button primary transition-colors w-fit"
        >
          Let's Talk
        </a>
      </div>
    </div>
  </div>
</div>
