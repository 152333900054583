import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-resume-evaluation-section',
  templateUrl: './ai-resume-evaluation-section.component.html',
  styleUrls: ['./ai-resume-evaluation-section.component.scss']
})
export class AiResumeEvaluationSectionComponent {

}
