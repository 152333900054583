import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-page-dialog',
  templateUrl: './leave-page-dialog.component.html',
  styleUrls: ['./leave-page-dialog.component.scss'],
})
export class LeavePageDialogComponent {
  countDownEndDate = '2024-05-14T12:00:00';
  isCountDownEnded: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
  getStartedUrl() {
    // const subdomain = environment.server === 'dev' ? 'dev' : 'app';
    // return `https://${subdomain}.uptal.com/register?sourcing-plan=get-started`;
    return this.data.url;
  }
}
