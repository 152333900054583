import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralTranslate',
  pure: false,
})
export class PluralTranslatePipe implements PipeTransform {
  private getPluralKey(number: number): string {
    if (number === 0) return 'zero';
    if (number === 1) return 'one';
    if (number === 2) return 'two';
    if (number >= 3 && number <= 10) return 'few';
    if (number >= 11 && number <= 99) return 'many';
    if (number >= 100) return 'other';

    return 'none';
  }
  transform(key: string, number: number, options = { skip: false }): string {
    if (options?.skip) return key;
    const pluralKey = this.getPluralKey(number);
    return `${key}.${pluralKey}`;
  }
}
