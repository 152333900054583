<ng-helmet>
  <title>AI Recruiter | Uptal</title>
</ng-helmet>
<landing-header [darkMode]="true"></landing-header>
<div>
  <!-- hero -->
  <div class="pt-28 md:pt-24 relative isolate overflow-hidden bg-white">
    <div
      class="absolute left-[calc(50%-4rem)] top-0 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
      aria-hidden="true"
    >
      <div
        class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4680e5] opacity-20"
        style="
          clip-path: polygon(
            73.6% 51.7%,
            91.7% 11.8%,
            100% 46.4%,
            97.4% 82.2%,
            92.5% 84.9%,
            75.7% 64%,
            55.3% 47.5%,
            46.5% 49.4%,
            45% 62.9%,
            50.3% 87.2%,
            21.3% 64.1%,
            0.1% 100%,
            5.4% 51.1%,
            21.4% 63.9%,
            58.9% 0.2%,
            73.6% 51.7%
          );
        "
      ></div>
    </div>
    <div class="mx-auto max-w-7xl px-6 pt-5 sm:pb-32 lg:px-8">
      <div
        class="hidden md:flex w-full mb-14 py-1 gap-x-8 justify-center items-center text-center"
      >
        <div *ngFor="let rating of stars">
          <div class="flex justify-center">
            <div *ngFor="let star of rating['stars']" class="text-yellow-400">
              <mat-icon
                *ngIf="star === 1"
                aria-hidden="false"
                fontIcon="star"
                class="text-lg"
              ></mat-icon>
              <mat-icon
                *ngIf="star === 0.5"
                aria-hidden="false"
                fontIcon="star_half"
                class="text-lg"
              ></mat-icon>
            </div>
          </div>
          <div class="text-xs font-semibold text-gray-600">
            {{ rating.key | translate }}
          </div>
        </div>
      </div>
      <div
        class="md:hidden w-full mb-8 flex py-1 gap-x-8 justify-center items-center text-center"
      >
        <div *ngFor="let rating of starsSmall">
          <div class="flex justify-center">
            <div *ngFor="let star of rating['stars']" class="text-yellow-400">
              <mat-icon
                *ngIf="star === 1"
                aria-hidden="false"
                fontIcon="star"
                class="text-lg"
              ></mat-icon>
              <mat-icon
                *ngIf="star === 0.5"
                aria-hidden="false"
                fontIcon="star_half"
                class="text-lg"
              ></mat-icon>
            </div>
          </div>
          <div class="text-xs font-semibold text-gray-600">
            "{{ rating.key | translate }}"
          </div>
        </div>
      </div>
      <div class="lg:flex">
        <div
          class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8"
        >
          <h1
            class="mt-10 text-4xl font-bold tracking-tight text-gray-700 sm:text-5xl"
          >
            {{ "sourcing.Access_to_Top_Talent" | translate }}
          </h1>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            {{ "sourcing.Hire_an" | translate }}
            <span class="font-semibold">{{
              "sourcing.AI_Recruiter" | translate
            }}</span>
            {{ "sourcing.to_find_the_most_suitable" | translate }}
          </p>
          <div
            class="mt-10 flex items-center justify-center md:justify-normal gap-x-6"
          >
            <a
              [href]="getStartedUrl()"
              class="rounded-full get-started-button bg-primary px-12 py-3 text-lg font-bold text-white shadow-sm hover:bg-blue-400"
              >{{ "sourcing.get_started" | translate }}</a
            >
          </div>
          <div class="hidden mt-4 md:flex items-center gap-x-3">
            <div *ngFor="let check of checks" class="flex items-center space-s-1.5">
              <mat-icon
                aria-hidden="false"
                fontIcon="done"
                class="text-lg font-bold text-green-500 !w-auto !h-auto "
              ></mat-icon>
              <span class="text-gray-600 text-xs">{{
                check[selectedLanguage]
              }}</span>
            </div>
          </div>
        </div>
        <button
          (click)="openVideo()"
          class="mx-auto mt-16 flex outline-none cursor-pointer max-w-2xl sm:mt-24 lg:ms-10 lg:me-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ms-10"
        >
          <div class="flex-none w-full relative">
            <img
              src="assets/images/sourcing/hero-faisal.png"
              alt="App screenshot"
              style="width: 610px"
              class="rounded-md bg-white/5 lg:w-1/3 shadow-2xl ring-1 ring-white/10"
            />
            <!-- <div class="absolute top-10 left-10">
            <div
              class="bg-blue-500 text-white h-24 w-24 rounded-full flex items-center text-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-8 h-8"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div> -->
          </div>
        </button>
      </div>
    </div>
  </div>

  <!-- trusted by  -->
  <div class="">
    <div class="page-max-width page-default-padding py-5 !max-w-screen-lg">
      <div data-aos="fade-up" class="space-y-10 pt-4 pb-12">
        <div>
          <p
            class="text-center text-2xl md:text-3xl font-bold text-gray-700 pb-4"
          >
            {{ "sourcing.Industry_Leader_Choice" | translate }}
          </p>
          <p class="text-center text-xl font-thin text-gray-500">
            {{ "sourcing.Top_Choice" | translate }}
          </p>
        </div>
        <div
          class="flex justify-center items-center flex-wrap gap-x-14 gap-y-10"
        >
          <img
            *ngFor="let trustedCompany of trustedCompanies"
            [style.height.px]="trustedCompany.height"
            [style.width]="'auto'"
            [src]="trustedCompany.src"
            alt="trusted companies logo"
            [height]="trustedCompany.h"
            [width]="trustedCompany.w"
          />
        </div>
      </div>
      <div class="text-center flex justify-end text-blue-500 hover:text-blue-600">
        <a [routerLink]="['/', 'ai']" [fragment]="'testimonials'">
          <div class="flex gap-x-1 items-center justify-end">
            <div>{{ "sourcing.Client_Feedback" | translate }}</div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 rtl:rotate-180"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <!-- how it works -->
  <div class="bg-gray-50">
    <div class="max-w-screen-xl px-4 mx-auto py-8">
      <div class="pb-8">
        <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
          {{ "sourcing.Process_Overview" | translate }}
        </p>
        <p
          class="mx-auto mt-3 max-w-2xl text-center text-lg leading-8 text-gray-500"
        >
          {{ "sourcing.Simple_Steps" | translate }}
        </p>
      </div>
      <div class="flex flex-col md:flex-row gap-6 w-full">
        <div class="w-full">
          <img src="assets/images/sourcing/steps/step1.png" alt="step1" />
          <div class="text-center px-2">
            <div class="font-bold mt-2">
              {{ "sourcing.Step_One" | translate }}
            </div>
            <div class="mt-1">
              {{ "sourcing.Step_One_Description" | translate }}
            </div>
          </div>
        </div>
        <div class="w-full mt-4 md:mt-0">
          <img src="assets/images/sourcing/steps/step2.png" alt="step2" />
          <div class="text-center px-2">
            <div class="font-bold mt-2">
              {{ "sourcing.Step_Two" | translate }}
            </div>
            <div class="mt-1">
              {{ "sourcing.Step_Two_Description" | translate }}
            </div>
          </div>
        </div>
        <div class="w-full mt-4 md:mt-0">
          <img src="assets/images/sourcing/steps/step3.png" alt="step3" />
          <div class="text-center px-2">
            <div class="font-bold mt-2">
              {{ "sourcing.Conclusion" | translate }}
            </div>
            <div class="mt-1">
              {{ "sourcing.AI_Recruiter_Process" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- pricing -->
  <div class="px-6 lg:px-8 py-8">
    <app-sourcing-plans (onSelect)="onSelectPlan($event)"></app-sourcing-plans>
  </div>

  <!-- testimonials -->
  <div id="testimonials" class="relative isolate bg-white pb-8 pt-24 sm:pt-8">
    <div
      class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
      aria-hidden="true"
    >
      <div
        class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#80a8ff] to-[#4461f0]"
        style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        "
      ></div>
    </div>
    <div
      class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
      aria-hidden="true"
    >
      <div
        class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#80a8ff] to-[#4461f0] xl:ml-0 xl:mr-[calc(50%-12rem)]"
        style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        "
      ></div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <h2
          class="text-lg font-semibold leading-8 tracking-tight text-blue-500"
        >
          {{ 'sourcing.Testimonials' | translate }}
        </h2>
        <p
          class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
        >
        {{ 'sourcing.Industry_Leaders' | translate }}
        </p>
      </div>
      <div
        class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4"
      >
        <figure
          class="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1"
        >
          <blockquote
            class="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8"
          >
            <p>“{{ "sourcing.Difficult_Role_Story" | translate }}”</p>
          </blockquote>
          <figcaption
            class="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap"
          >
            <img
              class="h-10 w-10 flex-none rounded-full bg-gray-50"
              src="assets/images/avatars/abdullah-al-khorasani-profile.jpeg"
              alt=""
            />
            <div class="flex-auto">
              <div class="font-semibold">
                {{ "sourcing.CEO_Name_Two" | translate }}
              </div>
              <div class="text-gray-600">
                {{ "sourcing.CEO_Title" | translate }}
              </div>
            </div>
            <img
              class="h-10 w-auto flex-none"
              src="assets/images/home/company-logos/gaga-logo.png"
              alt=""
            />
          </figcaption>
        </figure>
        <div class="space-y-8 xl:contents xl:space-y-0">
          <div class="space-y-8 xl:row-span-2">
            <figure
              class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
            >
              <blockquote class="text-gray-900">
                <p>“{{ "sourcing.Success_Stories" | translate }}”</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img
                  class="h-10 w-10 rounded-full bg-gray-50"
                  src="assets/images/avatars/abdullah-alzain-profile.jpeg"
                  alt=""
                />
                <div>
                  <div class="font-semibold">
                    {{ "sourcing.CEO_Name_One" | translate }}
                  </div>
                  <div class="text-gray-600">
                    {{ "sourcing.CEO_Title" | translate }}
                  </div>
                </div>
                <img
                  class="h-6 w-auto flex-none"
                  src="assets/images/home/company-logos/qparts-logo.jpg"
                  alt=""
                />
              </figcaption>
            </figure>

            <!-- More testimonials... -->
          </div>
          <!-- <div class="space-y-8 xl:row-start-1">
            <figure
              class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
            >
              <blockquote class="text-gray-900">
                <p>
                  “Aut reprehenderit voluptatem eum asperiores beatae id. Iure
                  molestiae ipsam ut officia rem nulla blanditiis.”
                </p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img
                  class="h-10 w-10 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <div>
                  <div class="font-semibold">Lindsay Walton</div>
                  <div class="text-gray-600">@lindsaywalton</div>
                </div>
              </figcaption>
            </figure>

          </div> -->
        </div>
        <div class="space-y-8 xl:contents xl:space-y-0">
          <!-- <div class="space-y-8 xl:row-start-1">
            <figure
              class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
            >
              <blockquote class="text-gray-900">
                <p>
                  “Voluptas quos itaque ipsam in voluptatem est. Iste eos
                  blanditiis repudiandae. Earum deserunt enim molestiae ipsum
                  perferendis recusandae saepe corrupti.”
                </p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img
                  class="h-10 w-10 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <div>
                  <div class="font-semibold">Tom Cook</div>
                  <div class="text-gray-600">@tomcook</div>
                </div>
              </figcaption>
            </figure>
          </div> -->
          <div class="space-y-8 xl:row-span-2">
            <figure
              class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
            >
              <blockquote class="text-gray-900">
                <p>“{{ "sourcing.Easy_Process" | translate }}”</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img
                  class="h-10 w-10 rounded-full bg-gray-50"
                  src="assets/images/avatars/danial-profile.png"
                  alt=""
                />
                <div>
                  <div class="font-semibold">
                    {{ "sourcing.Director_Name" | translate }}
                  </div>
                  <div class="text-gray-600">
                    {{ "sourcing.Associate_Director" | translate }}
                  </div>
                </div>
                <img
                  class="h-6 w-auto flex-none"
                  src="assets/images/home/company-logos/sets-logo.webp"
                  alt=""
                />
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Guarantee -->
  <div class="text-xl">
    <div class="!max-w-screen-xl mx-auto py-2 md:py-14 px-4">
      <div class="w-full p-4 md:px-20 md:py-8 rounded-md shadow bg-white">
        <div class="text-gray-600 font-light">
          {{ "sourcing.Money_Back_Guarantee" | translate }}
        </div>
        <div class="font-bold pt-2">
          {{ "sourcing.Satisfaction_Assurance" | translate }}
        </div>
        <div class="pt-4">
          {{ "sourcing.Company_Commitment" | translate }}
        </div>
        <div class="mt-4">
          <img
            class="h-16 w-auto"
            src="assets/images/sourcing/FaisalSignature.png"
            alt="Signature"
          />
        </div>
        <div class="pt-4 font-semibold">
          {{ "sourcing.Faisal_Alshareef" | translate }}
        </div>
        <div class=" ">{{ "sourcing.CEO_Statement" | translate }}</div>
      </div>
    </div>
  </div>

  <!-- faq -->
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          {{ "sourcing.FAQs" | translate }}
        </h2>
        <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
          <div *ngFor="let faq of faqs" class="pt-6">
            <dt>
              <!-- Expand/collapse question button -->
              <button
                type="button"
                (click)="faq.open = !faq.open"
                class="flex w-full items-start justify-between text-left text-gray-900"
                aria-controls="faq-0"
                aria-expanded="false"
              >
                <span class="text-base font-semibold leading-7">{{
                  faq.question | translate
                }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <svg
                    *ngIf="!faq.open"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v12m6-6H6"
                    />
                  </svg>

                  <svg
                    *ngIf="faq.open"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 12H6"
                    />
                  </svg>
                </span>
              </button>
            </dt>
            <dd *ngIf="faq.open" class="mt-2 pe-12" id="faq-0">
              <p class="text-base leading-7 text-gray-600">
                {{ faq.answer  | translate}}
              </p>
            </dd>
          </div>

          <!-- More questions... -->
        </dl>
      </div>
    </div>
  </div>
</div>
<main-footer></main-footer>
