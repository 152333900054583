import { inject, Injectable } from '@angular/core';
import {
  // QueryClientService,
  // UseInfiniteQuery,
  UseQuery,
  // UseMutation,
} from '@ngneat/query';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SourcingPlansService {
  // private useInfiniteQuery = inject(UseInfiniteQuery);
  private useQuery = inject(UseQuery);
  // private useMutation = inject(UseMutation);
  private http = inject(HttpClient);
  // private queryClient = inject(QueryClientService);
  constructor() {}

  list() {
    return this.useQuery(['sourcing-plans'], () =>
      this.http.get<any>(`${environment.apiUrl}plans`)
    );
  }
}
