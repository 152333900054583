<ng-helmet>
  <title>
    About Us | Uptal
  </title>
</ng-helmet>

<main-header [darkMode]="true"></main-header>
<div class="bg-white px-6 py-32 lg:px-8">
  <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
    <p class="text-base font-semibold leading-7 text-blue-600">About Us</p>
    <h1
      class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
    >
      Who We Are and Why We Do It
    </h1>
    <p class="mt-6 text-xl leading-8">
      At Uptal, we believe that finding and recruiting the right talent is the
      key to unlocking an organization's growth potential. That's why we've
      developed cutting-edge AI-powered recruitment software that streamlines
      the recruitment process, reduces time-to-hire, and enhances organizational
      capabilities to drive growth and competitiveness.
    </p>
    <div
      class="mt-10 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl"
    >
      Our Mission and Vision
    </div>
    <div class="mt-2 max-w-2xl">
      <p>
        Our mission is to empower organizations to hire smarter, faster, and
        with greater confidence through our technology-driven recruitment
        solutions. We envision a world where every company has access to the
        best talent and can unlock its full growth potential.
      </p>
    </div>
    <div
      class="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl"
    >
      Our Story
    </div>
    <div class="mt-2 max-w-2xl">
      <p>
        Uptal was born out of a desire to revolutionize the recruitment process.
        Our co-founders, who are serial entrepreneurs, experienced firsthand the
        challenges of finding and recruiting top talent. They saw an opportunity
        to leverage AI technology to make the recruitment process more efficient
        and effective. Since then, we've grown rapidly, serving a diverse range
        of clients across industries and geographies.
      </p>
    </div>
    <div
      class="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl"
    >
      Our Values
    </div>
    <div class="mt-2 max-w-2xl">
      <p>
        We are deeply committed to upholding our core values that drive our
        passion for delivering exceptional recruiting solutions. Our values
        guide every decision we make and shape our company culture, ensuring
        that we continually evolve and excel in the competitive landscape.
      </p>
    </div>
    <div>
      <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-blue-600"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd"
            />
          </svg>
          <span
            ><strong class="font-semibold text-gray-900">Innovation</strong> We
            believe in embracing cutting-edge technologies and creative
            problem-solving techniques to stay ahead of the curve and
            continually improve our software and services.</span
          >
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-blue-600"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd"
            />
          </svg>
          <span
            ><strong class="font-semibold text-gray-900"
              >Customer-Centricity</strong
            >
            Our focus is on understanding and addressing the unique needs of
            each client, as we strive to forge lasting relationships and deliver
            personalized solutions that exceed expectations.</span
          >
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-blue-600"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd"
            />
          </svg>
          <span
            ><strong class="font-semibold text-gray-900">Excellence</strong> We
            are dedicated to maintaining the highest quality standards in
            everything we do, from our software development to our customer
            support, ensuring that we provide the best possible experience for
            our clients.</span
          >
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-blue-600"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd"
            />
          </svg>
          <span
            ><strong class="font-semibold text-gray-900"
              >Diversity, Equity, and Inclusion</strong
            >
            We champion a diverse, equitable, and inclusive work environment
            that respects and values the unique perspectives, backgrounds, and
            experiences of all our employees, clients, and candidates. We
            recognize that embracing diversity is crucial for fostering
            creativity, driving innovation, and achieving success. our
            clients.</span
          >
        </li>
      </ul>
      <p class="mt-8">
        Our team is made up of passionate and dedicated professionals who share
        a common goal of transforming the recruitment industry. We have a
        diverse range of backgrounds, expertise, and perspectives, but we're
        united by our commitment to excellence and innovation.
      </p>
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">
        Manifesto
      </h2>
      <p class="mt-6">
        At Uptal, we believe that recruiting and talent acquisition should be
        more than just a process. We believe that it should be an opportunity to
        build better organizations and stronger communities.
      </p>
      <p class="mt-3">
        We believe that technology can play a powerful role in achieving this
        goal, but only if it is designed and implemented with purpose and
        empathy. That's why we created Uptal - a recruiting software that
        leverages artificial intelligence to help companies identify and attract
        the best talent, while also fostering diversity, equity, and inclusion
        in the hiring process.
      </p>
      <p class="mt-3">
        Our software uses AI algorithms to analyze candidate responses to
        pre-recorded video interviews, identifying traits such as communication
        skills, problem-solving ability, and cultural fit. This allows us to
        provide companies with a holistic view of each candidate, beyond just
        their resume or cover letter. Our AI algorithms also analyze the
        diversity of the candidate pool, ensuring that our clients are presented
        with a truly diverse and inclusive range of candidates.
      </p>
      <p class="mt-3">
        But we believe that technology is only part of the solution. That's why
        we are committed to working with our clients as true partners -
        listening to their needs, understanding their challenges, and
        collaborating to find solutions that work for them.
      </p>
      <p class="mt-3">
        Our software offers a range of services, including customized job
        descriptions, tailored interview questions, and personalized candidate
        communications. Our software is intuitive and easy to use, and we
        provide hands-on training and support to ensure that our clients get the
        most out of it.
      </p>
      <p class="mt-3">
        At Uptal, we are driven by a passion for innovation, a commitment to
        excellence, and a belief that we can make a real difference in the
        world. We believe that our technology can help companies build more
        effective teams, unlock new opportunities for growth and innovation, and
        create a more equitable and just society.
      </p>
      <p class="mt-3">
        We are proud to be a premium company and a leader in AI-powered
        recruiting technology, but we also know that we have a responsibility to
        use our technology for good. We are committed to fostering diversity,
        equity, and inclusion in the workplace, and to helping our clients do
        the same.
      </p>
      <p class="mt-3">
        We invite you to join us on this journey - a journey towards a better,
        more inclusive, and more innovative future.
      </p>
      <figure class="mt-10 border-l border-blue-600 pl-9">
        <blockquote class="font-semibold text-gray-900">
          <p>
            “We strive to revolutionize the hiring landscape by fostering
            meaningful connections between companies and candidates, and
            ultimately, unlocking the full potential of human capital.”
          </p>
        </blockquote>
        <figcaption class="mt-6 flex gap-x-4">
          <img
            class="h-6 w-6 flex-none rounded-full bg-gray-50"
            src="/assets/images/faisal.webp"
            alt="managing partner"
          />
          <div class="text-sm leading-6">
            <strong class="font-semibold text-gray-900"
              >Faisal Alshareef</strong
            >
            – Managing Partner
          </div>
        </figcaption>
      </figure>
    </div>
  </div>
</div>
<main-footer></main-footer>
