import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accelerated-hiring-section',
  templateUrl: './accelerated-hiring-section.component.html',
  styleUrls: ['./accelerated-hiring-section.component.scss'],
})
export class AcceleratedHiringSectionComponent implements AfterViewInit {
  @ViewChild('hiringCounter') hiringCounter: ElementRef;
  hiringCounterShown = false;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    this.handleScroll();
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) this.handleScroll();
  }

  handleScroll() {
    var rect = this.hiringCounter?.nativeElement?.getBoundingClientRect();
    var elemTop = rect?.top;
    var elemBottom = rect?.bottom;
    if (
      elemTop >= 0 &&
      elemBottom <= window.innerHeight &&
      !this.hiringCounterShown
    )
      this.hiringCounterShown = true;
  }

  getStartedUrl() {
    const subdomain = environment.server === 'dev' ? 'dev' : 'app';
    return `https://${subdomain}.uptal.com/register?sourcing-plan=get-started`;
  }
}
