import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
  GuardsCheckStart,
  GuardsCheckEnd,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RegionService } from './region.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs';

import * as AOS from 'aos';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

declare global {
  interface Window {
    Intercom: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'Uptal';
  loadingDataImg: boolean = false;
  loading = true;

  lang = 'en';
  ltrrtl = 'ltr';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private regionService: RegionService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
  ) {

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)){
      AOS.init({ easing: 'ease-in-out', once: true });
      this.handleIntercom();
    }
  }

  handleIntercom() {
    const user = this.authService.getUser();
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'wsq1mfzl',
      ...(user
        ? {
            user_id: user.id,
            name: `${user.first_name} ${user.last_name}`, // Full name
            email: user.email, // the email for your user
            created_at: user.createdAt, // Signup date as a Unix timestamp
            phone: user.mobile_number ?? '', // Phone number
            ...(user.avatar?.url
              ? {
                  avatar: {
                    type: 'avatar',
                    image_url: user.avatar?.url,
                  },
                }
              : {}),
          }
        : {}),
        hide_default_launcher: false,
    });
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof GuardsCheckStart) this.loading = true;
        if (
          event instanceof GuardsCheckEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationEnd
        )
          this.loading = false;
      });

      //Add dynamic title for selected pages - Start
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.Intercom("update");
          // if (window.HubSpotConversations) {
          //   const status = window.HubSpotConversations.widget.status();
          //   if (status.loaded) window.HubSpotConversations.widget.refresh();
          //   else window.HubSpotConversations.widget.load();
          // }
          // var title = this.getTitle(
          //   this.router.routerState,
          //   this.router.routerState.root
          // ).join(' > ');
          // this.titleService.setTitle(title);
        }
      });
    }
  }
}
