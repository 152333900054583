import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.document.location.replace(
      'https://meetings-eu1.hubspot.com/faisal-alshareef'
    );
    // if (environment.production)
    // this.document.location.href = `${this.document.location.protocol}//app.${this.document.location.host}${this.document.location.pathname}${this.document.location.search}`
  }
}
