import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss'],
})
export class CountDownComponent {
  @Input() endDate: string = '2021-12-31T00:00:00';
  @Output() onEnd = new EventEmitter();

  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;

  intervalId;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  ngOnInit(): void {
    this.calculateTimeUntilEndDate();
    if (isPlatformBrowser(this.platformId)) {
      this.setupInterval();
    }
  }

  setupInterval() {
    if (this.timeLeft) {
      this.intervalId = setInterval(() => {
        this.calculateTimeUntilEndDate();
      }, 1000);
    }
  }
  cleanupInterval() {
    if (this.intervalId) clearInterval(this.intervalId);
  }
  ngOnDestroy(): void {
    this.cleanupInterval();
  }

  calculateTimeUntilEndDate() {
    if (!this.timeLeft) {
      this.onEnd.emit(true);
      if (isPlatformBrowser(this.platformId)) this.cleanupInterval();
      return;
    }

    const now = moment();
    const end = moment(this.endDate);
    const difference = moment.duration(end.diff(now));

    this.days = difference.days();
    this.hours = difference.hours();
    this.minutes = difference.minutes();
    this.seconds = difference.seconds();
  }

  get timeLeft(): boolean {
    const now = moment();
    const end = moment(this.endDate);

    return end.isAfter(now);
  }
}
