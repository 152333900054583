<div class="space-y-4">
  <div class="space-y-4 !max-w-screen-lg m-auto">
    <div>
      <p class="text-sm md:text-xl text-primary text-center pb-3">
        AI Headhunting
      </p>
      <p class="text-center text-base md:text-3xl font-medium text-gray-600">
        Where finding the best possible candidate
        <br />
        for a position <span class="underline">Becomes Easy</span>.
      </p>
    </div>
    <div class="flex justify-center" data-aos="fade" data-aos-duration="500">
      <img src="assets/images/home/recruitment_modules_lg.webp" alt="ATS" width="1128" height="642" />
    </div>
  </div>
  <div class="space-y-4 !max-w-screen-lg m-auto">
    <div class="space-y-3" data-aos="fade" data-aos-duration="500">
      <p class="font-bold text-gray-700 text-lg md:text-3xl">
        AI Headhunting
      </p>
      <p class="text-gray-500">
        With Uptal's self-recorded interview feature, candidates can easily record their responses to customized
        interview questions at their own convenience.
      </p>
      <a routerLink="/register" target="_blank" class="flex items-center cursor-pointer group text-primary">
        <p class="group-hover:underline font-medium">
          Request a Demo
        </p>
        <mat-icon class="scale-90">chevron_right</mat-icon>
      </a>
    </div>
  </div>
</div>
