export const environment = {
  production: true,
  apiUrl: 'https://api.uptal.com/',
  server: 'prod',
  geolocation: 'https://api.db-ip.com/v2/free/self',
  google_analytics_tag: 'G-WCHTRS9GNK',
  airtable_api_url:
    'https://api.airtable.com/v0/appM2DL34VxsUSC7Y/Website Email Submit',
  airtable_api_key:
    'patIlHC0H4Yu778aT.015c0c3f8f4ff99b4c0c563e3702513e70a271ac97a068666d363596fc9a9244', // Note: Don't expose this in production! @metwaly please help hide this
};
