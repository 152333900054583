<div style="padding: 56.25% 0 0 0; position: relative">
  <iframe
    class="bg-blue-400"
    [ngClass]="{ 'bg-blue-400': !iframeLoaded, 'animate-pulse': !iframeLoaded }"
    (load)="iframeLoaded = true"
    src="https://player.vimeo.com/video/896292689?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    title="Uptal - AI Powered Interviews"
  ></iframe>
</div>
<script src="https://player.vimeo.com/api/player.js"></script>
