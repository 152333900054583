<ng-helmet>
  <title>
    Press | Uptal
  </title>
</ng-helmet>
<div class="w-full"></div>
<div class="w-full mx-auto">
  <div class="w-full py-8 max-w-3xl mx-auto text-center">
    <div class="text-4xl font-light">Contact Us</div>
    <div class="py-8">
      <a
        href="mailto:press@uptal.com"
        class="px-4 py-3 text-gray-700 hover:text-gray-500 border text-xl font-light border-gray-400 hover:border-gray:300 rounded-md cursor-pointer"
      >
        press@uptal.com
      </a>
      <div class="text-start py-8 text-gray-500 text-lg">
        Our press team loves working with journalists around the world to share
        compelling, unique stories. If you’re a member of the media and would
        like to talk, please get in touch with the appropriate team or send an
        email to press@uptal.com.
      </div>
    </div>
    <div class="py-8 w-full border-t border-gray-500">
      <div class="text-4xl font-light">Brand Assets</div>
      <div class="py-8 flex gap-x-10 gap-y-10 flex-wrap">
        <div *ngFor="let asset of brandAssets" class="relative">
          <div class="text-sm text-gray-500 text-start px-5 pt-4">
            {{ asset.name }}
          </div>
          <img
            src="{{ asset.image }}"
            alt="{{ asset.name }}"
            class="h-24 w-auto p-4"
            height="276"
            width="618"
          />
          <div
            (click)="downloadFile(asset.downloadLink)"
            class="w-full h-full absolute top-0 left-0 hover:opacity-100 opacity-0 cursor-pointer"
          >
            <div
              class="w-full h-full flex items-center justify-center bg-black bg-opacity-5 text-gray-600 transition duration-200 ease-in-out rounded-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="w-10 h-10 opacity-100 bg-white rounded-full p-2 transition duration-200 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
