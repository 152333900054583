<button
  type="button"
  for="uptal-language-select"
  class="flex items-center space-s-1 cursor-pointer outline-none"
  [ngClass]="{
    'text-primary': color === 'primary',
    'text-white': color === 'white'
  }"
  [matMenuTriggerFor]="menu"
>
  <mat-icon>language</mat-icon>
  <p *ngIf="fullSize">
    {{ currentLang }}
  </p>
  <mat-icon *ngIf="fullSize">keyboard_arrow_down</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let lang of filter(translate.getLangs()); trackBy: trackBy"
    (click)="onLanguageSelect(lang)"
  >
    {{ languages[lang] }}
  </button>
</mat-menu>
