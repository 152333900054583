<ng-helmet>
  <title>
    Contact Us | Uptal
  </title>
</ng-helmet>
<div>
  <div class="page-max-width !max-w-screen-md page-default-padding py-12 md:py-20 min-h-[820px]">
    <p data-aos="fade" [attr.data-aos-delay]="200"
      class="text-center text-2xl md:text-3xl font-bold text-gray-700 pb-12">
      Contact Us
    </p>
    <div data-aos="fade" [attr.data-aos-delay]="600" id="contact-us-hubspot-form"></div>
  </div>
</div>
