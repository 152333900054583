import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from './../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private geolocation

  constructor(private http: HttpClient) { }

  getGeolocation() {
    return new Observable((observer) => {
      if (this.geolocation) observer.next(this.geolocation)
      else fetch(environment.geolocation)
        .then((res) => {
          res.json()
            .then((data) => observer.next(data))
            .catch((err) => observer.error(err))
        })
        .catch((error) => observer.error(error))
    })
  }

  getCountries(params = {}) {
    return this.http.get<any>(`${environment.apiUrl}geo/countries`, { params })
  }
  getStates(params = {}) {
    return this.http.get<any>(`${environment.apiUrl}geo/states`, { params })
  }

  getCities(params = {}) {
    return this.http.get<any>(`${environment.apiUrl}geo/cities`, { params })
  }
}
