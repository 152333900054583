import { Component } from '@angular/core';

@Component({
  selector: 'app-security-and-privacy',
  templateUrl: './security-and-privacy.component.html',
  styleUrls: ['./security-and-privacy.component.scss']
})
export class SecurityAndPrivacyComponent {

}
