<ng-helmet>
  <title>
    Training & Support | Uptal
  </title>
</ng-helmet>
<div class="bg-white py-24 sm:py-8">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-3xl lg:text-center">
      <p
        class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
      >
        Ensuring Your Success
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        <strong>Partnership Approach: </strong>
        At Uptal, your milestones are our achievements. We work hand-in-hand
        with you to ensure that our solutions align perfectly with your hiring
        goals. Your success is not just an outcome - it's our joint mission.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl
        class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
      >
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </div>
            Best Practices Implementation
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>Job Posting</li>
              <li>Application Management</li>
              <li>Candidate Engagement</li>
              <li>Interview Scheduling</li>
              <li>Feedback and Collaboration</li>
              <li>Compliance and Reporting</li>
            </ul>
          </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Adoption icon"
                fontIcon="diversity_3"
                class="text-white"
              ></mat-icon>
            </div>
            Seamless Adoption Assurance
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>Smoothly integrate our tools into your existing workflow</li>
              <li>
                Provide change management support to ensure a seamless
                transition
              </li>
              <li>Offer strategies for ongoing engagement with our systems"</li>
            </ul>
          </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Training icon"
                fontIcon="groups"
                class="text-white"
              ></mat-icon>
            </div>
            Tailored Training Programs
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>Interactive sessions on using our tools effectively</li>
              <li>Best practices</li>
              <li>Regular updates on new features and enhancements</li>
            </ul>
          </dd>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div
              class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </div>
            Metrics Monitoring & Reporting
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            <ul>
              <li>Candidate Quality Index (CQI)</li>
              <li>Time-to-Hire Reductions</li>
              <li>Interview-to-Offer Ratios</li>
              <li>Hiring Efficiency Scores</li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
