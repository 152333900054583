import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-how-it-works-video',
  templateUrl: './how-it-works-video.component.html',
  styleUrls: ['./how-it-works-video.component.scss'],
})
export class HowItWorksVideoComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer
  ) {}
  iframeLoaded = false;
  ngOnInit(): void {}

  getUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data?.src ?? 'https://youtu.be/Ls2LM3pSog0'
    );
  }
}
