import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { SelectLanguageModule } from '../select-language/select-language.module';
import { RouterModule } from '@angular/router';
import { I18nModule } from '../i18n/i18n.module';



@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    CommonModule,
    SelectLanguageModule,
    RouterModule,
    I18nModule,
  ],
  exports: [
    FooterComponent,
  ]
})
export class FooterModule { }
