import { MatDialog } from '@angular/material/dialog';
import { HowItWorksVideoComponent } from './../how-it-works-video/how-it-works-video.component';
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RegionService } from '../region.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(private regionService: RegionService){}

  get region(){
    return this.regionService.region
  }
}
