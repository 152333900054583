import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-ai-recruiter-dialog',
  templateUrl: './ai-recruiter-dialog.component.html',
  styleUrls: ['./ai-recruiter-dialog.component.scss'],
})
export class AiRecruiterDialogComponent {
  selectedLanguage = this.translateService.currentLang;
  safeVideoUrl: SafeResourceUrl;

  videos = {
    en: 'https://www.youtube.com/embed/JrXxktyQz7s?si=i1kL9YrVSxLjEMbW',
    ar: 'https://www.youtube.com/embed/uGgMwOfiP70?si=K1enuHcI_YOOaHx8',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {
    this.updateVideoUrl();
    this.selectedLanguage = this.translateService.currentLang;

    translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
      this.updateVideoUrl();
    })
  }

  iframeLoaded = false;

  updateVideoUrl(): void {
    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.videos[this.selectedLanguage]
    );
  }

  ngOnInit(): void {}

}
