import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';

declare var hbspt;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements AfterViewInit, OnDestroy {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementsByTagName('body')[0].classList.add('scrollbar-hide');
      hbspt.forms.create({
        region: 'eu1',
        portalId: '139532807',
        formId: 'b8fd7882-50c4-4726-8d27-63ab18fa1736',
        target: '#contact-us-hubspot-form',
      });
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('scrollbar-hide');
    }
  }
}
