<ng-helmet>
  <title>
    Sourcing | Uptal
  </title>
</ng-helmet>
<div>
  <div class="page-max-width !max-w-screen-md space-y-16">
    <iframe class="aspect-video	 w-full" (load)="iframeLoaded = true"
      src="https://www.youtube.com/embed/TvEwMm94X7M?autoplay=1&mute=0&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1"
      frameborder="0" title="Uptal - Resume Sourcing" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen></iframe>
    <div data-aos="fade" [attr.data-aos-delay]="600" id="sourcing-hubspot-form"></div>
  </div>
</div>
