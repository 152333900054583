<div class="md:px-8 md:py-8 px-6 py-6 text-center max-w-xl">
  <div class="font-bold text-2xl">{{ "sourcing.hire_an_ai_recruiter" | translate }}</div>
  <div class="mt-4">
    {{ "sourcing.Tell_us_more_about_your_specific_needs_so_that_we_can_tailor_something_specific_to_you" | translate }}
  </div>
  <a class="outline-none hire-ai-recruiter-leave-dialog-button w-full" [href]="getStartedUrl()" mat-dialog-close>
    <div
      class="mt-4 bg-primary text-white w-full py-2 rounded-md hover:bg-blue-600 outline-none"
    >
      {{ "sourcing.Schedule_a_Demo" | translate }}
    </div>
  </a>
</div>
