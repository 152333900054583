import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private storageKey = environment.server === 'prod' ? "uptal_user" : "dev_uptal_user"


  constructor(private cookieService: CookieService) { }

  private _getUserLocalStorage() {
    if (this.isLoggedIn()) return JSON.parse(this.cookieService.get(this.storageKey))

    return {}
  }
  getUser() {
    return this._getUserLocalStorage()?.user
  }

  getAuthToken() {
    const user = this._getUserLocalStorage()
    if (user?.token) return user.token
    return ''
  }


  isLoggedIn() {
    return this.cookieService.get(this.storageKey)
  }

}
