<div  class="flex space-s-1.5 justify-center">
  <ng-container [ngTemplateOutlet]="slot" [ngTemplateOutletContext]="{label: 'sourcing.Time_Days', counter: days}">
  </ng-container>
  <ng-container [ngTemplateOutlet]="slot" [ngTemplateOutletContext]="{label: 'sourcing.Time_Hours', counter: hours}">
  </ng-container>
  <ng-container [ngTemplateOutlet]="slot" [ngTemplateOutletContext]="{label: 'sourcing.Time_Minutes', counter: minutes}">
  </ng-container>
  <ng-container [ngTemplateOutlet]="slot" [ngTemplateOutletContext]="{label: 'sourcing.Time_Seconds', counter: seconds}">
  </ng-container>
</div>

<ng-template #slot let-counter="counter" let-label="label">
  <div class="rounded bg-white w-12 flex flex-col items-center justify-center space-y-1 py-1 ltr:pt-1.5">
    <p class="text-lg font-medium text-center leading-none">
      {{ counter | arabicNumber }}
    </p>
    <p class="text-xs text-center uppercase leading-none">
      {{ label | translate }}
    </p>
  </div>
</ng-template>
